/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, {  useRef, useState } from 'react';
import { getSessionState } from "../../../../hooks/useSessionState";
import { Sidebar } from "primereact/sidebar";
import constantesOperacion from "../../../constantes";
import constantes from "../../../../catalogos/constantes"
import { ProgressBar } from 'primereact/progressbar';
import { FormattedDate } from "react-intl";
import moment from "moment";
import ButtonIcon from "../../../../shared/controls/ButtonIcon";
import DetalleSeguimiento from "./seguimientoDetalle";
import { produce } from "immer";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";
import { OverlayPanel } from "primereact/overlaypanel";
import useApiFlotillas from "../../../../api/comercial/useApiFlotillas";
import Notificaciones from "../notificaciones";
import * as _ from "lodash";
import FiltroSeguimiento from '../filtroSegumiento';
import alerts from "../../../../shared/alerts";
const Seguimiento = ({ soloLectura }) => {
    const api = useApiViajeSeguimiento();
    const apiUnidades = useApiFlotillas();
    const fechaActual = moment(new Date());
    const estatusNotificacion = { danger: 1, warning: 2, ok: 3 };

    const filtroKey = 'filtrosSeguimiento';
    const [viajes, setViajes] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [viajeId, setViajeId] = useState(0);
    const [mapaVisible, setMapaVisible] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const [setUnidadEnMapa] = useState(null);
    const [viaje, setViaje] = useState();
    const [geocercas, setGeocercas] = useState([]);
    const overlayRutas = useRef(null);
    const overlayNotificaciones = useRef(null);
    const esMonitoreo = false;


    const obtenerUnidades = async () => {
        const result = await apiUnidades.coordenadas();
        setUnidades(result);
    };

    async function buscar(filtro, hideShowLoading) {
        filtro = filtro ?? getSessionState(filtroKey);
        const nuevoFiltro = {
            ...filtro,
            rutaOrigenId: filtro.rutaOrigen?.id,
            rutaDestinoId: filtro.rutaDestino?.id,
            SubClienteId: filtro.subClientes?.id ?? 0,
            esMonitoreo,
        };
        filtro = nuevoFiltro ?? getSessionState(filtroKey);
        let viajes = await api.filtrar(filtro,!hideShowLoading);
        viajes.forEach((e) => agregarPropiedadesaViaje(e));
        viajes = viajes.filter(e => !e.quitar);
        viajes = _.sortBy(viajes, ["orden"]);
        filtrarUnidades(filtro);
        setViajes(viajes);
    }

    async function onSave() {
        setSidebarVisible(false);
        await buscar(getSessionState(filtroKey), true);
    }

    function filtrarUnidades(filtro) {
        if (filtro.unidades != null && filtro.unidades.length > 0) {
            setUnidades(unidades.filter(e => filtro.unidades.includes(e.id)));
        } else {
            obtenerUnidades().then();
        }
    }

    const getBarraClassName = (viaje) => {
        const colorBarra = !viaje.terminado && viaje.enTiempo ? 'viaje-a-tiempo' : !viaje.terminado ? 'viaje-retrasado' : '';
        return `d-flex flex-column cursor-pointer ${colorBarra}`
    };

    function abrirDetalle(viajeId = 0) {
        setSidebarVisible(true);
        setViajeId(viajeId);
    }

    //Si el viaje tiene segmentos, agrega propiedades del segmento en curso al viaje dado
    function agregarPropiedadesaViaje(viaje) {
        if (viaje.segmentos.length > 0) {
            const segmentos = viaje.segmentos;
            if (segmentos.some(e => e.estatusId !== constantesOperacion.estatusViajes.porProgramar)) {
                const segmentoEnCurso = segmentos.find(e => e.enCurso);
                const indexEnCurso = viaje.segmentos.findIndex(e => e.enCurso);
                let indexSegmentado = -1;
                viaje.segmentos.forEach(element => {
                    if (element.segmentado) indexSegmentado++;
                });

                if (segmentoEnCurso && indexEnCurso >= 0) {
                    viaje.unidad = segmentoEnCurso.unidad;
                    viaje.operador = segmentoEnCurso.operador;
                    viaje.telefonoABC = segmentoEnCurso.telefonoABC;
                    viaje.enTiempo = segmentoEnCurso.enTiempo;
                    viaje.notificacionesNoLeidas = segmentoEnCurso.notificacionesNoLeidas;
                    viaje.viajePendiente = false;
                } else if (segmentos.every(e => e.estatusId === constantesOperacion.estatusViajes.terminado)) {
                    const ultimoSegmento = viaje.segmentos[viaje.segmentos.length - 1];
                    viaje.unidad = ultimoSegmento.unidad;
                    viaje.operador = ultimoSegmento.operador;
                    viaje.telefonoABC = ultimoSegmento.telefonoABC;
                    viaje.enTiempo = ultimoSegmento.enTiempo;
                    viaje.notificacionesNoLeidas = ultimoSegmento.notificacionesNoLeidas;
                    viaje.viajePendiente = false;
                } else if (indexEnCurso === -1) {
                    if (indexSegmentado >= 0) {
                        const ultimoEnProceso = viaje.segmentos[indexSegmentado];
                        viaje.ultimaNotificacion = ultimoEnProceso.ultimaNotificacion;
                    }
                }
            } else {
                //Por medio de esta propiedad se quitaran de la tabla los viajes segmentados en donde todos sus segmentos son por programar
                viaje.quitar = true;
            }
        }
        agregarOrden(viaje);

        if (viaje.fechaInicio && viaje.estatusId === constantesOperacion.estatusViajes.programado && viaje.segmentos && viaje.segmentos.length === 0) {
            const fecha = moment(viaje.fechaInicio);
            const diferenciaMinutos = moment.duration(moment(fecha).diff(fechaActual)).asMinutes();
            viaje.proximoaIniciar = diferenciaMinutos <= 30;
        } else {
            viaje.proximoaIniciar = false;
        }
    }

    function agregarOrden(viaje) {
        if (viaje.terminado) {
            viaje.orden = 6;
            return;
        }

        viaje.orden = viaje.viajePendiente ? 5 : 4;

        if (viaje.ultimaNotificacion != null &&
            !viaje.viajePendiente &&
            (viaje.estatusId !== constantesOperacion.estatusViajes.porProgramar && (viaje.estatusId !== constantesOperacion.estatusViajes.programado))
        ) {
            const fecha = moment(viaje.ultimaNotificacion.fecha);
            const diferenciaMinutos = moment.duration(moment(fechaActual).diff(fecha)).asMinutes();

            if (diferenciaMinutos >= 30) {
                viaje.estatusNotificacion = diferenciaMinutos < 60 ? estatusNotificacion.warning : estatusNotificacion.danger;
                viaje.orden = diferenciaMinutos < 60 ? 3 : 2;
            }
        }

        //viaje.orden = !viaje.enTiempo ? 1 : viaje.orden;
        viaje.orden = viaje.estatusNotificacion === estatusNotificacion.warning ? 2 : viaje.orden;
        viaje.orden = viaje.estatusNotificacion === estatusNotificacion.danger ? 1 : viaje.orden;
    }

    function exportar() {
        api.excel(getSessionState(filtroKey));
    }

    const obtenerEstiloHora = (viaje) => {
        const warning = viaje.estatusNotificacion === estatusNotificacion.warning ? 'bg-warning text-white' : '';
        const danger = viaje.estatusNotificacion === estatusNotificacion.danger ? 'bg-danger text-white' : '';

        return `p-2 rounded ${warning} ${danger}`;
    };

    const mostrarMapa = () => {
        const indexUnidadEnMapa = viajes.findIndex(e => e.visibleEnMapa);

        //Si hay alguna unidad seleccionada para mostrar en el mapa se quita si el mapa no será visible
        if (indexUnidadEnMapa >= 0 && mapaVisible) {
            const newValue = produce(viajes, (draft) => {
                draft[indexUnidadEnMapa].visibleEnMapa = false;
            });

            setViajes(newValue);
        }

        if (mapaVisible) {
            setUnidadEnMapa(null);
        }

        setMapaVisible(!mapaVisible);
    };

    const mostrarUnidadEnMapa = (index) => {
        const viaje = viajes[index];
        const indexVisibleAnterior = viajes.findIndex(e => e.visibleEnMapa);

        const newValue = produce(viajes, (draft) => {
            draft[index].visibleEnMapa = !draft[index].visibleEnMapa;

            //Si estaba seleccionada una unidad se quita para solo mostrar la nueva
            if (indexVisibleAnterior >= 0) {
                draft[indexVisibleAnterior].visibleEnMapa = false;
            }
        });

        const mostrarUnidad = newValue[index].visibleEnMapa;
        const estatus = constantesOperacion.unidadViajeEstatusId;
        const estatusUnidad = viaje.enTiempo ? estatus.enTiempo : estatus.retrasado;
        const unidadEnMapa = mostrarUnidad ? { ...viaje.unidad, estatusId: estatusUnidad } : null;

        setUnidadEnMapa(unidadEnMapa);
        setMapaVisible(mapaVisible || mostrarUnidad);

        if (mostrarUnidad) {
            document.querySelector('article').scrollTo(0, 0)
        }

        setViajes(newValue);
    };

    const obtenerGeocercas = async (e, viaje) => {
        overlayRutas.current.toggle(e);

        if (!geocercas.some(e => e.viajeId === viaje.id)) {
            const result = await api.obtenerGeocercas(viaje.id);
            setGeocercas(result);
        }
    };

    const obtenerNotificaciones = async (viaje, index) => {
        setViaje(viaje);

        const newValue = produce(viajes, (draft) => {
            draft[index].notificacionesNoLeidas = 0;
        });

        setViajes(newValue);
    };


    const obtenerEstiloTbody = () => {
        return mapaVisible ? { maxHeight: '500px' } : { height: '94%' }
    };
    async function solicitarDryRun(item) {
        if (await alerts.preguntarSiNoAdvertencia("¿Está seguro de generar el viaje como Dry Run?")) {
            await api.solicitamosDryRun(item.id);
            await buscar();
        }
    }

    return (
        <>
            <FiltroSeguimiento buscar={buscar} filtroKey={filtroKey} mapaVisible={mapaVisible}
                setMapaVisible={mostrarMapa} />
            <Sidebar visible={sidebarVisible} position="right" dismissable={false} className="p-sidebar-bg"
                onHide={() => setSidebarVisible(false)}>
                <DetalleSeguimiento viajeId={viajeId} onSave={onSave} close={() => setSidebarVisible(false)} soloLectura={soloLectura} />
            </Sidebar>

            <OverlayPanel ref={overlayRutas} className="bg-white overlaypanel-sm">
                <table className="table table-borderless mb-0" style={{ lineHeight: 1.2 }}>
                    <tbody>
                        {geocercas.map((e, index) =>
                            <tr key={e.id}>
                                <td style={{ maxWidth: '120px' }}>
                                    <b>{e.nombre}</b>
                                    {index + 1 < geocercas.length &&
                                        <div className="pt-2"><i className="pi pi-ellipsis-v" /></div>
                                    }
                                </td>

                                <td>
                                    <div className="d-flex flex-column">
                                        <span>Hora Sug.: {e.fechayHoraSugerida}</span>
                                        <span>Hora Real: {e.fechayHoraReal}</span>

                                        {e.horaReal &&
                                            <div className={`d-flex mt-1 text-${e.enTiempo ? 'success' : 'danger'}`}>
                                                <i className={`pi ${e.enTiempo ? 'pi-check-circle' : 'pi-times-circle'}`} />
                                                <span className="pl-1">{e.enTiempo ? "A TIEMPO" : "RETRASO"}</span>
                                            </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </OverlayPanel>

            <OverlayPanel ref={overlayNotificaciones} className="bg-white arrow-right overlaypanel-sm">
                <Notificaciones unidadId={viaje?.unidad?.id} viajeId={viaje?.id} width="360px"
                    filtro={{ esResumenNotificaciones: true }} soloLectura={soloLectura} />
            </OverlayPanel>

            <div className="table-custom">
                <table className="table table-sm table-striped table-fixed"
                    style={{ height: mapaVisible ? 'auto' : '98%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}>ESTATUS VIAJE</th>
                            <th style={{ width: "25%" }}>VIAJE</th>
                            <th style={{ width: "7%" }}>CARTA PORTE</th>
                            <th style={{ width: "15%" }}>OPERARIO/UNIDAD</th>
                            <th style={{ width: "15%" }}>ÚLTIMO COMENTARIO</th>
                            <th style={{ width: "12%" }}>ÚLTIMA ACTUALIZACIÓN</th>
                            <th className="text-left" style={{ width: "8%" }}>ESTATUS</th>
                            <th style={{ width: "8%" }}>
                                <div className="d-flex justify-content-end">
                                    <ButtonIcon title="Descargar" onClick={exportar} iconName={'BiDownload'} />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody style={obtenerEstiloTbody()}>
                        {viajes.map((item, index) => {
                            return (
                                <>
                                    <tr key={item.id} className={item.proximoaIniciar ? 'text-danger' : ''}>
                                        <td style={{ width: "10%" }}>
                                            <div className={getBarraClassName(item)} style={{ lineHeight: 1.1 }}
                                                onClick={(e) => obtenerGeocercas(e, item)}>
                                                <ProgressBar value={item.porcentajeViaje} showValue={false}
                                                    color={`${item.enTiempo ? '#00d661' : '#ff4b4b'}`} />
                                                <div>
                                                    <FormattedDate value={item.fechaInicio} weekday="short" /> &nbsp;
                                                    {moment(item.fechaInicio).format('DD/MM/YYYY')}
                                                </div>
                                                <span><b>ETA: {item.eta}</b></span>
                                            </div>
                                        </td>

                                        <td style={{ width: "25%" }}>
                                            <b>{item.cliente || 'VACÍO'}</b>
                                            <span className="pl-1 text-muted"
                                                style={{ fontSize: '0.8em' }}>{item.idViaje != null && item.idViaje !== "" && item.idViaje}
                                                {item.idViajeRetorno != null && item.idViajeRetorno !== "" &&
                                                    ` / ${item.idViajeRetorno} (Ret.)`
                                                }</span>

                                            <div className="d-flex flex-wrap align-items-center"
                                                style={{ fontSize: '0.8em' }}>
                                                {item.rutas.map((r, index) => {
                                                    return <React.Fragment key={r.id}>
                                                        <div>{`${r.nombre} (${r.fechayHora})`}</div>
                                                        {index < item.rutas.length - 1 &&
                                                            <i className="pi pi-arrow-right px-1" />
                                                        }
                                                    </React.Fragment>
                                                }
                                                )}
                                            </div>
                                        </td>

                                        <td className="text-center" style={{ width: "7%" }}>
                                            {item.cartaPorte}
                                        </td>

                                        <td style={{ width: "15%" }}>
                                            <div className="d-flex flex-column justify-content-center">
                                                <div>{item.operador}</div>
                                                <div>{item.telefonoABC}</div>
                                                <div>
                                                    Unidad: <b>{item.unidad?.nombre}</b>&nbsp;
                                                    | Caja: <b>{item.caja ?? ' Vacío'}</b>
                                                </div>
                                            </div>
                                        </td>

                                        <td style={{ width: "15%" }}>
                                            {item.ultimaNotificacion?.comentario}
                                        </td>

                                        <td className="text-center" style={{ width: "12%" }}>
                                            <div className={obtenerEstiloHora(item)} style={{ width: '50%', margin: 'auto' }}>
                                                {item.ultimaNotificacion == null ? '' :
                                                    moment(item.ultimaNotificacion.fecha).format('hh:mm A')}
                                            </div>
                                        </td>

                                        <td style={{ width: "8%" }}>
                                            {item.estatus}
                                        </td>

                                        <td style={{ width: "8%" }}>
                                            <div className="d-flex justify-content-end">
                                                <div className="position-relative pr-1"
                                                    onClick={(e) => overlayNotificaciones.current.toggle(e)}>
                                                    <ButtonIcon iconName={'IoIosNotificationsOutline'}
                                                        title="Notificaciones"
                                                        onClick={() => obtenerNotificaciones(item, index)} />

                                                    {item.notificacionesNoLeidas > 0 &&
                                                        <span
                                                            className="badge text-white rounded-pill bg-danger position-absolute"
                                                            style={{ top: '2px', right: '2px' }}>{item.notificacionesNoLeidas}
                                                        </span>
                                                    }
                                                </div>

                                                {item.unidad?.posicion == null ?
                                                    <div style={{ width: '36px' }} />
                                                    : <ButtonIcon title="Ubicación" iconName={'VscLocation'}
                                                        className={item.visibleEnMapa ? "text-primary" : ""}
                                                        onClick={() => mostrarUnidadEnMapa(index)} />
                                                }
                                                {item.clienteId === constantes.cliente.Penske && (
                                                    item.estatusId === constantesOperacion.estatusViajes.enRuta || 
                                                    item.estatusId ===  constantesOperacion.estatusViajes.programado) &&
                                                    
                                                     <div className="d-flex align-items-center">
                                                        <div className="table-button flex-grow-1">
                                                                <>
                                                                    <button title="Dry Run" type="button" className="button-icon"
                                                                    onClick={() => solicitarDryRun(item, index)}>
                                                                        <i className="pi pi-times-circle text"/>
                                                                    </button>

                                                                </>
                                                                
                                                        </div>
                                                        </div>                           
                                                     
                                                }
                                                
                                                <button type="button" className="btn btn-link btn-sm"
                                                    onClick={() => abrirDetalle(item.id)}>
                                                    VER
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Seguimiento;


