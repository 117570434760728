/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import FieldRadioButtonList from "../../shared/controls/fieldRadioButtonList";
import Direccion from "../direccion";
import clienteValidacionEsquema from "./clienteValidacionEsquema";
import {Button} from "primereact/button";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import constantes from "../constantes";
import useApiClientes from "../../api/catalogos/useApiClientes";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import {Accordion, AccordionTab} from "primereact/accordion";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import produce from "immer";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import ConfiguracionLear from "./configuracionLear";
import alerts from "../../shared/alerts";
import SubClientes from "./subClientes";
import SftpEDI from "./sftpEdi";
import Qs3 from "./qs3";
import FieldScanner from "../../shared/controls/fieldScanner";
import usePersistedState from "../../hooks/usePersistedState";
import useSeguridad, {opcionesSeguridad} from "../../Seguridad/useSeguridad";
import InputFile from "../../shared/controls/inputFile";
import FieldChips from "../../shared/controls/FieldChips";

const DetalleCliente = ({clienteId, onSave, soloLectura}) => {
    const apiClientes = useApiClientes();
    const [active] = useState(null);
    const [maniobra] = useState(2);
    const [indexConceptos, setIndex] = useState(0);
    const [conceptosPrefacturacion, setConceptosPrefacturacion] = useState([]);
    const [escaner, setEscaner] = usePersistedState("escanerSeleccionado", null);

    const [visibleEscanear, setVisibleEscanear] = useState();
    const [visibleEditarConfiguracionEDI, setVisibleEditarConfiguracionEDI] = useState();
    const [visibleEditarConfiguracionQS3, setVisibleEditarConfiguracionQS3] = useState();

    const {tieneAcceso} = useSeguridad();
    useEffect(() => {
        setVisibleEscanear(tieneAcceso(opcionesSeguridad.escanear));
        setVisibleEditarConfiguracionEDI(tieneAcceso(opcionesSeguridad.editarConfiguracionEDI));
        setVisibleEditarConfiguracionQS3(tieneAcceso(opcionesSeguridad.editarConfiguracionQS3));
    }, []);

    const defaultCliente = () => ({
        entity: {
            tipoPersona: constantes.tiposPersona.moral,
            tipoCliente: {id: constantes.tiposCliente.nacional},
            rfcGenerico:true,
            moneda: {id: constantes.monedas.pesos},
            direccionFiscal: {
                pais: {id: constantes.paises.mexico},
                colonia: "NA",
            },
            direcciones: [
                {direccionFiscal: {pais: {id: constantes.paises.mexico}, colonia: "NA"}, default: true},
            ]
          
        },
    });

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultCliente);
    const formHandler = new FormHandler({
        validationSchema: clienteValidacionEsquema.guardar,
        onSave,
        api: apiClientes,
        dispatch,
        onSetErrors: setTabLevelErrors,
    });

    useEffect(() => {
        let timer1 = setTimeout(loadCliente, 100);
        // this will clear Timeout when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer1);
        };
    }, [clienteId]);

    const loadCliente = async () => {
        if (clienteId == null) return;

        let cliente = {entity: await apiClientes.obtener(clienteId)};

        if (clienteId === 0) {
            var monedaDefault = {id: constantes.monedas.pesos};
            var direccionesDefault = [
                {direccionFiscal: {pais: {id: constantes.paises.mexico}, colonia: "NA"}, esDefault: true},
            ];

            cliente.entity.moneda = monedaDefault;
            cliente.entity.direcciones = direccionesDefault;
            cliente.entity.datosPago = {plazo: 0, metodoPago: null, formaPago: null};
            cliente.entity.documentacion = [];
            cliente.entity.estatus = constantes.estatus.clientes.enProceso;
            cliente.entity.tipoPersona = constantes.tiposPersona.moral;
            cliente.entity.tipoCliente = {id: constantes.tiposCliente.nacional};
            cliente.entity.rfcGenerico = entity.rfcGenerico;
        }

        //const result = clienteId !== 0 ? await apiClientes.obtenerConveniosPorCliente(clienteId) : [];
        //setConvenios(result.convenios.concat(result.expeditados));

        const Conceptos = await apiClientes.obtenerConceptos();
        setConceptosPrefacturacion(Conceptos);
        dispatch({type: "reset", getDefault: () => cliente});
    };

    const save = async (estatus) => {
        const esquema = estatus === "guardar" ? clienteValidacionEsquema.guardar : clienteValidacionEsquema.alta;

        formHandler.setValidacionSchema(esquema);
        // localStorage.setItem("anexo", entity.documentacion[0].anexo);
        const empleadoGuardado = await formHandler.save(entity);
        await subirConstancia();
        if (formHandler.valdation.result === true) {
            onSave();
        }
        return empleadoGuardado;
    };

    const subirConstancia = async () => {
        if (entity.archivo?.file != null) {
            const formData = new FormData();
            formData.append("file", entity.archivo?.file);
            await apiClientes.subirConstancia(entity?.id, formData);
        }
    }
    const update = (value, name) => dispatch({type: "update", value, name});

    function setTabLevelErrors(errors) {
        return {
            ...errors,
            generales:
                errors.razonSocial ||
                errors.rFC ||
                errors.usoCfdi ||
                errors.plazo ||
                errors.nombre ||
                errors.apellidoPaterno ||
                errors.apellidoMaterno,
            direccionFiscal: errors.direcciones,
            datosPago: errors.datosPago,
        };
    }

    function actualizarLista(lista, index, value, propertyName, namelist) {
        if (propertyName === "esDefault" && value !== null) {
            for (let i = 0; i < lista.length; i++) {
                lista = produce(lista, (draft) => {
                    draft[i][propertyName] = false;
                });
            }
        }

        const nuevo = produce(lista, (draft) => {
            if (propertyName === "documentoEspecificacionesId" && value.length === 0) {
                draft[index][propertyName] = [{id: 0}];
            } else draft[index][propertyName] = value;
        });

        update(nuevo, namelist);
    }

    function actualizarDatosPago(datosPago, value, propertyName) {
        const nuevo = produce(datosPago, (draft) => {
            draft[propertyName] = value;
        });

        update(nuevo, "datosPago");
    }

    function agregarALista(lista, namelist) {
        var numeroDireccion = lista.length + 1;
        const nuevo = produce(lista, (draft) => {
            draft.splice(numeroDireccion, 0, {
                direccionFiscal: {pais: {id: constantes.paises.mexico}, colonia: "NA"},
            });
        });

        update(nuevo, namelist);
    }

    function eliminarDeLista(lista, index, namelist) {
        const nuevo = produce(lista, (draft) => {
            draft.splice(index, 1);
        });
        update(nuevo, namelist);
    }

    const cambiarEstatus = async (estatus) => {
        const saved = await save(estatus);

        if (formHandler.valdation.result === true) {
            if (
                entity.estatus === constantes.estatus.clientes.enProceso ||
                entity.estatus === constantes.estatus.clientes.suspendido
            )
                await apiClientes.alta(saved.id);
            if (entity.estatus === constantes.estatus.clientes.vigente) await apiClientes.baja(saved.id);
            if (onSave != null) onSave();
        }
    };

    function seleccionarElementos(value) {
        if (entity.conceptosFacturacion.length === 0) return;

        const nuevo = produce(entity.conceptosFacturacion, (draft) => {
            if (draft[indexConceptos].descripcion == null) draft[indexConceptos].descripcion = "";
            draft[indexConceptos].descripcion += " " + value;
        });

        update(nuevo, "conceptosFacturacion");
    }

    const actualizarEmpresas = (value, name, index) => {
        if (name === "empresaId") {
            if (entity.clientesSaas?.some((c) => c.empresaId === value)) {
                alerts.aviso("La empresa ya esta asignada a este cliente.");
                return;
            }
        }

        const newValue = produce(entity.clientesSaas, (draft) => {
            draft[index][name] = value;
        });

        update(newValue, "clientesSaas");
    };

    const agregarEmpresa = () => {
        const newValue = produce(entity.clientesSaas, (draft) => {
            draft.push({id: 0});
        });

        update(newValue, "clientesSaas");
    };

    const eliminarEmpresa = (index) => {
        const newValue = produce(entity.clientesSaas, (draft) => {
            draft.splice(index, 1);
        });

        update(newValue, "clientesSaas");
    };

    return (
        <>
            <form>
                <div className="form-container full-height">
                    <ReadOnlyProvider defaultReadOnly={soloLectura}>
                        <TabView>
                            <TabPanel
                                header="GENERALES"
                                rightIcon={errors?.generales ? "pi pi-exclamation-circle" : ""}
                            >
                                <div className="form-row">
                                    {/***************************Datos Generales*/}
                                    <h5 className="col-12">Datos Generales</h5>
                                    <FieldRadioButtonList
                                        name="tipoPersona"
                                        label="Tipo Persona"
                                        colMd={6}
                                        source="tiposPersona"
                                        value={entity.tipoPersona}
                                        onChange={update}
                                    />

                                    {entity.tipoPersona === constantes.tiposPersona.moral && (
                                        <FieldTextBox
                                            name="razonSocial"
                                            label="Razón Social"
                                            colMd={6}
                                            maxLength={100}
                                            errors={errors}
                                            value={entity.razonSocial}
                                            onChange={update}
                                        />
                                    )}

                                    <div>
                                        Constancia Fiscal:
                                        <InputFile
                                            name="archivo"
                                            value={entity.archivo}
                                            onChange={update}
                                        />
                                    </div>


                                    {entity.tipoPersona === constantes.tiposPersona.fisica && (
                                        <>
                                            <FieldTextBox
                                                name="nombre"
                                                label="Nombre"
                                                colMd={12}
                                                errors={errors}
                                                value={entity.nombre}
                                                onChange={update}
                                            />
                                            <FieldTextBox
                                                name="apellidoPaterno"
                                                label="Apellido Paterno"
                                                colMd={12}
                                                errors={errors}
                                                value={entity.apellidoPaterno}
                                                onChange={update}
                                            />
                                            <FieldTextBox
                                                name="apellidoMaterno"
                                                label="Apellido Materno"
                                                colMd={12}
                                                errors={errors}
                                                value={entity.apellidoMaterno}
                                                onChange={update}
                                            />
                                        </>
                                    )}
                                    <FieldDropDown
                                        name="usoCfdi"
                                        label="Uso CFDI"
                                        colMd={12}
                                        source="usosCfdi"
                                        filter
                                        showClear={false}
                                        errors={errors}
                                        value={entity.usoCfdi}
                                        onChange={update}
                                    />
                                    <FieldTextBox
                                        name="rfc"
                                        label="RFC"
                                        colMd={6}
                                        maxLength={13}
                                        errors={errors}
                                        value={entity.rfc}
                                        onChange={update}
                                    />
                                    <FieldDropDown
                                        name="tipoCliente"
                                        label="Tipo Cliente"
                                        errors={errors}
                                        colMd={6}
                                        source="tiposCliente"
                                        showClear={false}
                                        value={entity.tipoCliente}
                                        onChange={update}
                                    />
                                    <FieldCheckBox
                                        name="incluirIdViajeExterno"
                                        label="Id Viaje Requerido"
                                        value={entity.incluirIdViajeExterno}
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                    {entity?.incluirIdViajeExterno &&
                                        <FieldCheckBox
                                            name="reutilizarIdViaje"
                                            label="Reutilizar Id Viaje"
                                            value={entity.reutilizarIdViaje}
                                            onChange={update}
                                            className="py-1"
                                            colMd={3}/>
                                    }
                                    <FieldCheckBox
                                        name="incluirIdViajeRequerido"
                                        label="Id Viaje Externo"
                                        value={entity.incluirIdViajeRequerido}
                                        onChange={update}
                                        className="py-1"
                                        colMd={2}
                                    />
                                    <FieldCheckBox
                                        name="facturarSinTimbrar"
                                        label="Facturar sin Timbrar"
                                        value={entity.facturarSinTimbrar}
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                    <FieldCheckBox
                                        name="comentarioRequerido"
                                        label="Comentario obligatorio"
                                        value={entity.comentarioRequerido}
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                    <FieldCheckBox
                                        name="requiereOrdenCompra"
                                        label="Requiere Orden de Compra"
                                        value={entity.requiereOrdenCompra}
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                </div>

                                <div className="form-row">
                                    <FieldCheckBox
                                        name="esLogistico"
                                        label="Es Logistico"
                                        value={entity.esLogistico}
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel
                                header="DIRECCIÓN FISCAL"
                                rightIcon={errors?.direcciones ? "pi pi-exclamation-circle" : ""}
                            >
                                <div className="">
                                    <table className="table table-borderless">
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col" style={{width: "90%"}}>
                                                DOMICILIO
                                            </th>
                                            <th className={"input"} style={{width: "10%"}}>
                                                <ButtonIcon
                                                    title={"Agregar Ruta"}
                                                    iconName={"SiAddthis"}
                                                    onClick={() => agregarALista(entity.direcciones, "direcciones")}
                                                />
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>

                                    {entity.direcciones?.map((item, index) => {
                                        return (
                                            <Accordion
                                                style={{marginTop: "1%"}}
                                                activeIndex={active}
                                                multiple
                                                key={index}
                                            >
                                                <AccordionTab
                                                    header={
                                                        <>
                                                            <span>Direccion {index + 1} </span>
                                                            <div
                                                                onClick={(event) => event.stopPropagation()}
                                                                style={{width: "-60px", float: "right"}}
                                                                className="d-flex"
                                                            >
                                                                <FieldCheckBox
                                                                    width="30%"
                                                                    floatposition="right"
                                                                    flexDirection="row-reverse"
                                                                    name="esDefault"
                                                                    label="Direccion Default"
                                                                    value={item.esDefault}
                                                                    onChange={(value, pName) =>
                                                                        actualizarLista(
                                                                            entity.direcciones,
                                                                            index,
                                                                            value,
                                                                            pName,
                                                                            "direcciones"
                                                                        )
                                                                    }
                                                                    className="py-1"
                                                                    colMd={6}
                                                                />
                                                                {entity.direcciones.length > 1 && (
                                                                    <ButtonIcon
                                                                        title={"Eliminar"}
                                                                        marginTop={"6px"}
                                                                        onClick={() =>
                                                                            eliminarDeLista(
                                                                                entity.direcciones,
                                                                                index,
                                                                                "direcciones"
                                                                            )
                                                                        }
                                                                        iconName={"RiDeleteBinLine"}
                                                                    />
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <>
                                                        <div className="form-row">
                                                            <Direccion
                                                                value={item.direccionFiscal}
                                                                name="direccionFiscal"
                                                                mostrarExternalId={true}
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.direcciones,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "direcciones"
                                                                    )
                                                                }
                                                                errors={
                                                                    errors != null &&
                                                                    errors[`direcciones[${index}]`] != null
                                                                        ? errors[`direcciones[${index}]`]
                                                                            .direccionFiscal
                                                                        : ""
                                                                }
                                                            />
                                                            <FieldTextBox
                                                                name="telefono"
                                                                label="Teléfono"
                                                                colMd={6}
                                                                value={item.telefono}
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.direcciones,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "direcciones"
                                                                    )
                                                                }
                                                                errors={
                                                                    errors != null &&
                                                                    errors[`direcciones[${index}]`] != null
                                                                        ? errors[`direcciones[${index}]`]
                                                                        : ""
                                                                }
                                                            />
                                                            <FieldTextBox
                                                                name="emailFacturacion"
                                                                label="Correo de Facturación"
                                                                colMd={6}
                                                                errors={
                                                                    errors != null &&
                                                                    errors[`direcciones[${index}]`] != null
                                                                        ? errors[`direcciones[${index}]`]
                                                                        : ""
                                                                }
                                                                value={item.emailFacturacion}
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.direcciones,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "direcciones"
                                                                    )
                                                                }
                                                                maxLength={500}
                                                            />
                                                        </div>
                                                    </>
                                                </AccordionTab>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </TabPanel>
                            <TabPanel
                                header="DATOS DE PAGO"
                                rightIcon={errors?.datosPago ? "pi pi-exclamation-circle" : ""}
                            >
                                <div className="form-row">
                                    <FieldInputNumber
                                        name="plazo"
                                        label="Plazo (días)"
                                        colMd={6}
                                        errors={errors?.datosPago}
                                        value={entity.datosPago?.plazo}
                                        onChange={(value, pName) => actualizarDatosPago(entity.datosPago, value, pName)}
                                    />
                                    <FieldDropDown
                                        name="moneda"
                                        label="Moneda"
                                        colMd={6}
                                        source="monedas"
                                        showClear={false}
                                        value={entity.moneda}
                                        onChange={update}
                                    />
                                    <FieldDropDown
                                        name="metodoPago"
                                        label="Método de Pago"
                                        colMd={6}
                                        source="metodosPago"
                                        showClear={false}
                                        errors={errors?.datosPago}
                                        value={entity.datosPago?.metodoPago}
                                        onChange={(value, pName) => actualizarDatosPago(entity.datosPago, value, pName)}
                                    />
                                    <FieldDropDown
                                        name="formaPago"
                                        label="Forma de Pago"
                                        colMd={6}
                                        source="formasPago"
                                        showClear={false}
                                        errors={errors?.datosPago}
                                        value={entity.datosPago?.formaPago}
                                        onChange={(value, pName) => actualizarDatosPago(entity.datosPago, value, pName)}
                                    />
                                    <FieldTextBox
                                        name="noCuenta"
                                        label="No. Cuenta (últimos 4 dígitos)"
                                        colMd={6}
                                        keyFilter="int"
                                        value={entity.datosPago?.noCuenta}
                                        onChange={(value, pName) => actualizarDatosPago(entity.datosPago, value, pName)}
                                        maxLength={4}
                                    />
                                    <FieldTextBox
                                        name={"numRegIdTrib"}
                                        label={"Tax ID"}
                                        colMd={6}
                                        keyFilter={"int"}
                                        value={entity.datosPago?.numRegIdTrib}
                                        maxLength={15}
                                        onChange={(value, pName) => actualizarDatosPago(entity.datosPago, value, pName)}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel
                                header="FACTURACION"
                                rightIcon={
                                    errors?.conceptosFacturacion || errors?.documentacion
                                        ? "pi pi-exclamation-circle"
                                        : ""
                                }
                            >
                                <div className="form-row">
                                    <FieldMultiSelect
                                        name="agrupadores"
                                        source="tiposAgrupador"
                                        label="Ver viajes agrupados por"
                                        colMd={4}
                                        value={entity.agrupadores}
                                        onChange={update}
                                    />
                                    <FieldDropDown
                                        name="addenda"
                                        label="Addenda"
                                        colMd={4}
                                        source="addendas"
                                        value={entity.addenda}
                                        onChange={update}
                                    />
                                    <FieldCheckBox
                                        name="rfcGenerico"
                                        label="RFC Genérico"
                                        value={entity.rfcGenerico} 
                                        onChange={update}
                                        className="py-1"
                                        colMd={3}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <table className="table table-borderless">
                                            <thead className="thead-light">
                                            <tr>
                                                <th style={{width: "25%"}}>Empresa</th>
                                                <th style={{width: "25%"}}>Identificador Externo</th>
                                                <th style={{width: "60%"}}>Correo</th>

                                                <th className={"ag-grid header-icons"} style={{width: "10%"}}>
                                                    {!soloLectura && (
                                                        <ButtonIcon
                                                            title={"Agregar Empresa"}
                                                            iconName={"SiAddthis"}
                                                            className="grid-action"
                                                            onClick={agregarEmpresa}
                                                        />
                                                    )}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {entity?.clientesSaas?.map((item, index) => {
                                                return (
                                                    <tr style={{position: "relative"}} key={index}>
                                                        <td style={{display: "flex"}} className="input">
                                                            <FieldDropDown
                                                                name="empresaId"
                                                                colMd={12}
                                                                source="todasEmpresas"
                                                                value={item.empresaId}
                                                                valueById={true}
                                                                onChange={(value, pName) =>
                                                                    actualizarEmpresas(value, pName, index)
                                                                }
                                                            />
                                                        </td>
                                                        <td className="input">
                                                            <FieldTextBox
                                                                name={"externalId"}
                                                                colMd={12}
                                                                keyFilter={"int"}
                                                                value={item?.externalId}
                                                                maxLength={5}
                                                                onChange={(value, pName) =>
                                                                    actualizarEmpresas(value, pName, index)
                                                                }
                                                            />
                                                        </td>

                                                        <td className="input">
                                                            <FieldTextBox
                                                                name="email"
                                                                colMd={12}
                                                                maxLength={500}
                                                                value={item?.email}
                                                                onChange={(value, pName) =>
                                                                    actualizarEmpresas(value, pName, index)
                                                                }
                                                            />
                                                        </td>

                                                        <td>
                                                            {!soloLectura && (
                                                                <ButtonIcon
                                                                    title={"Eliminar"}
                                                                    iconName={"RiDeleteBinLine"}
                                                                    onClick={() => eliminarEmpresa(index)}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-8" style={{marginTop: "10px"}}>
                                        <table className="table table-borderless">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col" style={{width: "20%"}}>
                                                    CONCEPTOS
                                                </th>
                                                <th scope="col" style={{width: "80%"}}>
                                                    DESCRIPCION
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {entity.conceptosFacturacion?.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {item.conceptoFacturacion.id !== maniobra && (
                                                            <tr key={index}>
                                                                <td className="input">
                                                                    {item.conceptoFacturacion.nombre}
                                                                </td>
                                                                <td className="input">
                                                                    <FieldTextArea
                                                                        rows={4}
                                                                        maxLength={1000}
                                                                        name="descripcion"
                                                                        colMd={6}
                                                                        onFocus={() => setIndex(index)}
                                                                        errors={
                                                                            errors != null &&
                                                                            errors[
                                                                                `conceptosFacturacion[${index}]`
                                                                                ] != null
                                                                                ? errors[
                                                                                    `conceptosFacturacion[${index}]`
                                                                                    ]
                                                                                : ""
                                                                        }
                                                                        value={item.descripcion}
                                                                        onChange={(value, pName) =>
                                                                            actualizarLista(
                                                                                entity.conceptosFacturacion,
                                                                                index,
                                                                                value,
                                                                                pName,
                                                                                "conceptosFacturacion"
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div
                                        style={{display: "table", marginTop: "10px"}}
                                        className="col-4 table-custom table-in-panel"
                                    >
                                        <table className="table table-sm  table-hover table-fixed">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col" style={{width: "100%"}}>
                                                    CAMPOS
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {conceptosPrefacturacion?.map((item, index) => {
                                                return (
                                                    <tr
                                                        onClick={() => seleccionarElementos(item.concepto)}
                                                        key={index}
                                                    >
                                                        <td className="input">{item.nombre}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-4"}>
                                        <h5>Papeleria Fisica</h5>
                                        <div style={{marginTop: "20px"}}>
                                            <FieldRadioButtonList
                                                name="papeleriaFisica"
                                                label="Tipo Persona"
                                                colMd={6}
                                                source="papeleriaFisica"
                                                value={entity.papeleriaFisica}
                                                onChange={update}/>
                                        </div>
                                    </div>
                                    <div className={"col-lg-2 col-4"}>
                                        {visibleEscanear && (
                                            <>
                                                <h5>Escaner</h5>
                                                <FieldDropDown
                                                    value={escaner}
                                                    onChange={setEscaner}
                                                    colMd={12}
                                                    source={"escaners"}
                                                    filter={false}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div style={{marginTop: "10px", width: "100%"}}>
                                        <table style={{marginTop: "30px"}} className="table">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col" style={{width: "10%", verticalAlign: "middle"}}>
                                                    DEFAULT
                                                </th>
                                                <th scope="col" style={{width: "15%", verticalAlign: "middle"}}>
                                                    DOCUMENTO
                                                </th>
                                                <th scope="col" style={{width: "15%", verticalAlign: "middle"}}>
                                                    NOMBRE
                                                </th>
                                                <th scope="col" style={{width: "15%", verticalAlign: "middle"}}>
                                                    ESPECIFICACIONES
                                                </th>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        width: entity?.esLogistico ? "15%" : "15%",
                                                        verticalAlign: "middle",
                                                    }}
                                                >
                                                    NOTAS
                                                </th>
                                                {entity?.esLogistico && (
                                                    <th
                                                        scope="col"
                                                        style={{width: "10%", verticalAlign: "middle"}}
                                                    >
                                                        SUB-CLIENTE
                                                    </th>
                                                )}
                                                <th scope="col" style={{width: "15%", verticalAlign: "middle"}}>
                                                    Archivo
                                                </th>
                                                <th className={"ag-grid header-icons"} style={{width: "5%"}}>
                                                    {!soloLectura && (
                                                        <ButtonIcon
                                                            title={"Agregar Ruta"}
                                                            iconName={"SiAddthis"}
                                                            className="grid-action"
                                                            onClick={() =>
                                                                agregarALista(entity.documentacion, "documentacion")
                                                            }
                                                        />
                                                    )}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {entity.documentacion?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="input">
                                                            <FieldCheckBox
                                                                name="predeterminado"
                                                                value={item.predeterminado}
                                                                onChange={(value, pname) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pname,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td style={{display: "flex"}} className="input">
                                                            <FieldDropDown
                                                                name="documentoCategoriaId"
                                                                source="documentos"
                                                                colMd={6}
                                                                value={item.documentoCategoriaId}
                                                                errors={
                                                                    errors != null &&
                                                                    errors[`documentacion[${index}]`] != null
                                                                        ? errors[`documentacion[${index}]`]
                                                                        : ""
                                                                }
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td className="input">
                                                            <FieldTextBox
                                                                name="descripcion"
                                                                value={item.descripcion}
                                                                colMd={6}
                                                                placeholder="Opcional"
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td style={{display: "flex"}} className="input">
                                                            <FieldMultiSelect
                                                                name="documentoEspecificacionesId"
                                                                source="especificaciones"
                                                                colMd={4}
                                                                value={item.documentoEspecificacionesId}
                                                                errors={
                                                                    errors != null &&
                                                                    errors[`documentacion[${index}]`] != null
                                                                        ? errors[`documentacion[${index}]`]
                                                                        : ""
                                                                }
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td className="input">
                                                            <FieldTextArea
                                                                rows={2}
                                                                maxLength={1000}
                                                                name="notas"
                                                                value={item?.notas}
                                                                colMd={6}
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        {entity?.esLogistico && (
                                                            <td style={{display: "flex"}} className="input">
                                                                <FieldDropDown
                                                                    name="clienteSubCliente"
                                                                    source="subClientes"
                                                                    colMd={6}
                                                                    value={item.clienteSubCliente}
                                                                    errors={
                                                                        errors != null &&
                                                                        errors[`documentacion[${index}]`] != null
                                                                            ? errors[`documentacion[${index}]`]
                                                                            : ""
                                                                    }
                                                                    onChange={(value, pName) =>
                                                                        actualizarLista(
                                                                            entity.documentacion,
                                                                            index,
                                                                            value,
                                                                            pName,
                                                                            "documentacion"
                                                                        )
                                                                    }
                                                                    params={{clienteId: entity?.id}}
                                                                />
                                                            </td>
                                                        )}
                                                        <td className="input">
                                                            <FieldScanner
                                                                name={"anexo"}
                                                                value={item.anexo}
                                                                disabled={soloLectura}
                                                                scanApiUrl={"escaner"}
                                                                escanerId={escaner?.id}
                                                                visibleEscanear={visibleEscanear}
                                                                params={{modulo: "Clientes_Documentos"}}
                                                                uploadFileUrl={"archivos/subir/Clientes_Documentos"}
                                                                // onChange={()=>{}}
                                                                onChange={(value, pName) =>
                                                                    actualizarLista(
                                                                        entity.documentacion,
                                                                        index,
                                                                        value,
                                                                        pName,
                                                                        "documentacion"
                                                                    )
                                                                }
                                                                empresa={constantes.empresa.ABC}
                                                            />
                                                            {/*<InputFile*/}
                                                            {/*    name="anexo"*/}
                                                            {/*    value={item.anexo}*/}
                                                            {/*    accept={true}*/}
                                                            {/*    disabled={soloLectura}*/}
                                                            {/*    errors={*/}
                                                            {/*        errors != null &&*/}
                                                            {/*        errors[`documentacion[${index}]`] != null*/}
                                                            {/*            ? errors[`documentacion[${index}]`]*/}
                                                            {/*            : ""*/}
                                                            {/*    }*/}
                                                            {/*    onChange={(value, pName) =>*/}
                                                            {/*        actualizarLista(*/}
                                                            {/*            entity.documentacion,*/}
                                                            {/*            index,*/}
                                                            {/*            value,*/}
                                                            {/*            pName,*/}
                                                            {/*            "documentacion"*/}
                                                            {/*        )*/}
                                                            {/*    }*/}
                                                            {/*/>*/}
                                                        </td>
                                                        <td className="input">
                                                            {!soloLectura && (
                                                                <ButtonIcon
                                                                    title={"Eliminar"}
                                                                    iconName={"RiDeleteBinLine"}
                                                                    onClick={() =>
                                                                        eliminarDeLista(
                                                                            entity.documentacion,
                                                                            index,
                                                                            "documentacion"
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="CONFIGURACIÓN">
                                <div className="form-row">
                                    <h5>Información unidades y remolques</h5>
                                    <FieldInputNumber
                                        name="antiguedadUnidad"
                                        label="Unidad antiguedad máxima"
                                        value={entity.antiguedadUnidad}
                                        colMd={12}
                                        onChange={update}
                                    ></FieldInputNumber>

                                    <FieldInputNumber
                                        name="antiguedadRemolque"
                                        label="Remolque antiguedad máxima"
                                        value={entity.antiguedadRemolque}
                                        colMd={12}
                                        onChange={update}
                                    ></FieldInputNumber>
                                    <h5>Api Clientes</h5>
                                    <FieldChips
                                        name="reportarUnidades"
                                        label="Reportar Unidades (comma o enter para asignar)"
                                        value={entity.reportarUnidades}
                                        colMd={12}
                                        onChange={update}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel
                                header="CONFIGURACIÓN LEAR"
                                rightIcon={errors?.clientesFacturadores ? "pi pi-exclamation-circle" : ""}
                                errors={errors}
                            >
                                <ConfiguracionLear entity={entity} onChange={update} soloLectura={soloLectura}/>
                            </TabPanel>
                            {entity.esLogistico && (
                                <TabPanel
                                    header="SUBCLIENTES"
                                    rightIcon={errors?.subClientes ? "pi pi-exclamation-circle" : ""}
                                    errors={errors}
                                >
                                    <SubClientes entity={entity} onChange={update} soloLectura={soloLectura}/>
                                </TabPanel>
                            )}
                            {visibleEditarConfiguracionEDI && (
                                <TabPanel header="CONFIGURACIÓN EDI">
                                    <FieldCheckBox
                                        name="tieneEDI"
                                        label="Tiene EDI"
                                        value={entity?.tieneEDI}
                                        onChange={update}
                                        className="py-1"
                                        colMd={12}
                                    />
                                    {entity?.tieneEDI && <SftpEDI entity={entity} onChange={update}/>}
                                </TabPanel>
                            )}
                            {visibleEditarConfiguracionQS3 && (
                                <TabPanel header="CONFIGURACIÓN QS3">
                                    <FieldCheckBox
                                        name="tieneQS3"
                                        label="Tiene QS3"
                                        value={entity?.tieneQS3}
                                        onChange={update}
                                        className="py-1"
                                        colMd={12}
                                    />
                                    {entity?.tieneQS3 && <Qs3 entity={entity} onChange={update}/>}
                                </TabPanel>
                            )}
                        </TabView>
                    </ReadOnlyProvider>
                </div>
                <div className="form-footer">
                    {entity.estatus !== constantes.estatus.clientes.vigente && !soloLectura && (
                        <Button
                            className="p-button-outlined"
                            label={"Dar de Alta"}
                            type="button"
                            onClick={() => cambiarEstatus("alta")}
                        />
                    )}

                    {entity.estatus === constantes.estatus.clientes.vigente && !soloLectura && (
                        <Button
                            label="Suspender"
                            className="p-button-outlined p-button-danger"
                            type="button"
                            onClick={() => cambiarEstatus("suspender")}
                        />
                    )}
                    {!soloLectura && <Button label="Guardar" type="button" onClick={() => save("guardar")}/>}
                </div>
            </form>
        </>
    );
};

export default DetalleCliente;
