/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import formReducer from "../../shared/forms/formReducer";
import { TabPanel, TabView } from "primereact/tabview";
import Generales from "./secciones/generales";
import FormHandler from "../../shared/forms/formHandler";
import useApiEmpleados from "../../api/catalogos/useApiEmpleados";
import DatosPersonales from "./secciones/datosPersonales";
import Nomina from "./secciones/nomina";
import IMMS from "./secciones/imss";
import Historial from "./secciones/historial";
import HistorialRetiroCajaAhorro from "./secciones/retiroCajaAhorroHistorial";
import AbonoAhorroHistorial from "./secciones/abonoAhorroHistorial";
import Descuentos from "../../administracion/liquidaciones/secciones/descuentos";
import Archivos from "./secciones/archivos";
import { Button } from "primereact/button";
import empleadoValidacionEsquema from "./empleadoValidacionEsquema";
import constantes from "../constantes";
import BajaEmpleado from "./secciones/bajaEmpleado";
import Retirocajaahorro from "./secciones/retirocajaahorro";
import Abonoahorro from "./secciones/abonoahorro";
import ReIngresoEmpleado from "./secciones/reIngresoEmpleado";
import useSeguridad from "../../Seguridad/useSeguridad";
import alerts from "../../shared/alerts";
import moment from "moment";
import EquiposEmpleado from "./secciones/equiposEmpleado";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import {useLoadingDispatch} from "../../layout/show-loading-context";
import  validarClabe  from './validacionClabeTarjeta'; 
import  validarTarjeta from './validacionNoTarjeta'; 
import swal from 'sweetalert2'
import CapacitacionesEmpleados from "./secciones/capacitacionesEmpleados";


const DetalleEmpleado = ({ onUpdateStatus, onSave, empleadoId, soloLectura }) => {
    const apiEmpleados = useApiEmpleados();
    const [esquemaValidacion, setEsquemaValidacion] = useState(empleadoValidacionEsquema);
    const [fechaBaja] = useState(moment().startOf('day').toDate());
    const seguridad = useSeguridad();
    const validateRfc = require('validate-rfc');
    const permisoHistorialEmpleados = seguridad.tieneAcceso(constantes.opciones.HistorialEmpleados);
    const permisoCajaAhorro = seguridad.tieneAcceso(constantes.opciones.PermisoCajaAhorro);
    const permisoVerCapacitaciones = seguridad.tieneAcceso(constantes.opciones.permisoVerCapacitaciones);
    const permisoPrestamoCajaAhorro = seguridad.tieneAcceso(constantes.opciones.PermisoPrestamoCajaAhorro);
    const [modoCajaahorro, setmodoCajaahorro] = useState(false);
    const loadingDispatch = useLoadingDispatch();
    const MAX_FILE_SIZE = 10 * 1024 * 1024; 
    const defaultEmpleado = () => (
        {
            entity: {
                familiares: [],
                antidoping: [],
                actasAdministrativas: [],
                historial: [],
                licencias: [],
                archivosAdicionales: [],
                nomina: {
                    SueldoBaseLiquidacion: seguridad.tieneAcceso(constantes.opciones.SueldoBaseLiquidacion),
                    SueldoBaseStaff: seguridad.tieneAcceso(constantes.opciones.SueldoBaseStaff),
                    SueldoBaseOficina: seguridad.tieneAcceso(constantes.opciones.SueldoBaseOficina),
                },
                recursosConfiables: [],
                empleadoBitacoras: []
            }
        }
    );

    const [{ entity, errors, ...state }, dispatch] = useReducer(
        formReducer, {}, defaultEmpleado
    );

    useEffect(() => {
        let timer1 = setTimeout(loadEmpleado, 100);
        // this will clear Timeout when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer1)
        }
    }, [empleadoId]);

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.setValidacionSchema(esquemaValidacion);
            formHandler.validate(entity);
        }
    }, [entity]);

    const loadEmpleado = async () => {
        if (empleadoId == null)
            return;

        let empleado = { entity: await apiEmpleados.obtener(empleadoId) };
        //empleado.entity.nomina.puedeVerSueldo = opciones.findIndex(e=>e.id === constantes.opciones.verSueldoBase) >= 0;
        empleado.entity.nomina.SueldoBaseLiquidacion = seguridad.tieneAcceso(constantes.opciones.SueldoBaseLiquidacion);
        empleado.entity.nomina.SueldoBaseStaff = seguridad.tieneAcceso(constantes.opciones.SueldoBaseStaff);
        empleado.entity.nomina.SueldoBaseOficina = seguridad.tieneAcceso(constantes.opciones.SueldoBaseOficina);

        if (empleado.entity.datosPersonales.estado == null)
            empleado.entity.datosPersonales.estado = empleado.entity.datosPersonales?.estados?.nombre ?? 'NA';

        if (empleado.entity.datosPersonales.municipio == null)
            empleado.entity.datosPersonales.municipio = empleado.entity.datosPersonales?.municipios?.nombre ?? 'NA';

        dispatch({ type: 'reset', getDefault: () => empleado });      
        if(empleado.entity?.nomina.ahorro>0){
            setmodoCajaahorro(true)
        }
        console.log(empleado)
    };



    const formHandler = new FormHandler({
        validationSchema: empleadoValidacionEsquema.alta,
        api: apiEmpleados,
        dispatch
    });

    const save = async (callValidation) => {
        const response = validateRfc(entity.datosGenerales?.rfc);
        if (!response.isValid) {
            alerts.aviso('RFC con formato invalido');
            return;
        }

        const esquema = callValidation === true || entity.estatus === constantes.estatus.empleados.alta ?
            empleadoValidacionEsquema.alta :
            empleadoValidacionEsquema.guardar;

        setEsquemaValidacion(esquema);

        formHandler.setValidacionSchema(esquema);
        // Validación de CLABE 
        if(entity.nomina?.clabe != null){    
            const  validationResult = await validarClabe(entity.nomina?.clabe);
            if (!validationResult.isValid) {
                swal.fire({
                titleText: 'Atención.',
                text: validationResult.error + 'del apartado de Datos Bancarios',
                icon: 'warning',
                });
                return;
                
            }
        }
        if(entity.nomina?.clabeSeg !== null && entity.nomina?.clabeSeg !== "" ){    
            const validationSegResult =  await validarClabe(entity.nomina?.clabeSeg);
            if (!validationSegResult.isValid) {
                swal.fire({
                titleText: 'Atención.',
                text: validationSegResult.error + ' del apartado de Otra Información Bancaria',
                icon: 'warning',
                });
                return;
            }
        }
        // Validación de Tarjeta 
        if(entity.nomina?.numeroTarjeta !== null && entity.nomina?.numeroTarjeta !==""){   
            const validationNoTarjetaResult = validarTarjeta(entity.nomina?.numeroTarjeta);
            if (!validationNoTarjetaResult.isValid) {
                swal.fire({
                titleText: 'Atención.',
                text: validationNoTarjetaResult.error + ' del apartado de Datos Bancarios',
                icon: 'warning',
                });
                return;
            }
        }
        if(entity.nomina?.numeroTarjetaSeg !== null && entity.nomina?.numeroTarjetaSeg !==""){   
            const validationNoTarjetaResult = validarTarjeta(entity.nomina?.numeroTarjetaSeg);
            if (!validationNoTarjetaResult.isValid) {
                swal.fire({
                titleText: 'Atención.',
                text: validationNoTarjetaResult.error + 'del apartado de Otra Información Bancaria',
                icon: 'warning',
                });
                return;
            }
        }
        const empleadoGuardado = await formHandler.save(entity, true);

        if (!formHandler.valdation.result) {
            loadingDispatch({type: 'toast', message: 'Favor de revisar campos requeridos o inválidos', severity: 'warn'});
            return
        }

        if (empleadoGuardado.id === 0) {
            return;
        }
        await subirArchivos(empleadoGuardado.id, entity);
        await subirFoto(empleadoGuardado.id, entity);
        await subirAnexosAntidoping(empleadoGuardado.id, entity);
        await subirAnexosActasAdministrativas(empleadoGuardado.id, entity);
        await subirAnexosActasFamiliares(empleadoGuardado.id, entity);
        await subirAnexoslicencias(empleadoGuardado.id, entity);
        await subirAnexosRecursosConfiables(empleadoGuardado.id, entity);

        await subirArchivosAdicionales(empleadoGuardado.id, entity);
        onSave();

        return empleadoGuardado;
    };

    const darDeAlta = async () => {
        const response = validateRfc(entity.datosGenerales?.rfc);
        if (!response.isValid) {
            alerts.aviso('RFC con formato invalido');
            return;
        }

        const saved = await save(true);
        if (formHandler.valdation.result === true) {
            await apiEmpleados.alta(saved.id);
            await apiEmpleados.envioCorreo(saved.id, { motivo: '', comentarios: '', fechaBaja: fechaBaja, alta: true });

            if (onUpdateStatus != null)
                onUpdateStatus();
        }
    };

    async function subirArchivos(id, empleado) {
        const documentosGenerales = empleado.documentosGenerales.filter(e => e.archivo?.file != null);
       
        const documentoConArchivo = documentosGenerales.find((e) => e.archivo?.file);
        const fileSize = documentoConArchivo?.archivo.file.size;
        if (fileSize> MAX_FILE_SIZE) {
            alerts.informacion('El archivo es demasiado grande. Por favor, selecciona uno menor a 10 MB.');
            return;
        }
        
        if (documentosGenerales.length > 0) {
            const formData = new FormData();
            for (const documento of documentosGenerales) {
                formData.append("files", documento.archivo.file);
            }
            formData.append(
                "tiposDocumentos",
                JSON.stringify(documentosGenerales.map(e => e.tipoDocumento.id))
            );

            await apiEmpleados.subirArchivos(id, formData);
        }

        const documentosOperador = empleado.documentosOperador.filter(e => e.archivo?.file != null);

        const documentosOperadorconArchivo = documentosOperador.find((e) => e.archivo?.file);
        const fileSizeArchivoOp = documentosOperadorconArchivo?.archivo.file.size;
        if (fileSizeArchivoOp> MAX_FILE_SIZE) {
            alerts.informacion('El archivo es demasiado grande. Por favor, selecciona uno menor a 10 MB.');
            return;
        }
        

        if (documentosOperador.length > 0) {
            const formDataOperador = new FormData();
            for (const documento of documentosOperador) {
                formDataOperador.append("files", documento.archivo.file);
            }
            formDataOperador.append(
                "tiposDocumentos",
                JSON.stringify(documentosOperador.map(e => e.tipoDocumento.id))
            );

            await apiEmpleados.subirArchivos(id, formDataOperador);
        }
    }

    async function subirArchivosAdicionales(id, empleado) {
        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.archivosAdicionales.length; i++) {
            const archivo = empleado.archivosAdicionales[i].archivo;
            if (archivo?.file != null) {
                indexes.push(i);
                formData.append('files', archivo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirArchivosAdicionales(id, formData);
        }
    }

    async function subirFoto(id, empleado) {
        if (empleado.foto?.file != null) {
            const formData = new FormData();
            formData.append("file", empleado.foto.file);
            await apiEmpleados.subirFoto(id, formData);
        }
    }

    async function subirAnexosAntidoping(id, empleado) {

        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.antidoping.length; i++) {
            const anexo = empleado.antidoping[i].anexo;
            if (anexo?.file != null) {
                indexes.push(i);
                formData.append('files', anexo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirAnexosAntidoping(id, formData);
        }
    }


    async function subirAnexosRecursosConfiables(id, empleado) {

        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.recursosConfiables.length; i++) {
            const anexo = empleado.recursosConfiables[i].anexo;
            if (anexo?.file != null) {
                indexes.push(i);
                formData.append('files', anexo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirAnexosRecursosConfiables(id, formData);
        }
    }

    async function subirAnexosActasAdministrativas(id, empleado) {

        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.actasAdministrativas.length; i++) {
            const anexo = empleado.actasAdministrativas[i].anexo;
            if (anexo?.file != null) {
                indexes.push(i);
                formData.append('files', anexo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirAnexosActasAdministrativas(id, formData);
        }
    }


    async function subirAnexosActasFamiliares(id, empleado) {

        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.familiares.length; i++) {
            const anexo = empleado.familiares[i].acta;
            if (anexo?.file != null) {
                indexes.push(i);
                formData.append('files', anexo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirAnexosActasFamiliares(id, formData);
        }
    }


    async function subirAnexoslicencias(id, empleado) {

        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < empleado.licencias.length; i++) {
            const anexo = empleado.licencias[i].archivo;
            if (anexo?.file != null) {
                indexes.push(i);
                formData.append('files', anexo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append(
                "listIndexes",
                JSON.stringify(indexes)
            );
            await apiEmpleados.subirAnexosLicencias(id, formData);
        }
    }


    const update = (value, name, esCambioEnTipoEmpleado) => {
        if (esCambioEnTipoEmpleado) {

            if (value.tipoEmpleado == null) {
                dispatch({ type: 'update', value: entity.documentosGenerales, name: 'documentosGenerales' });
                dispatch({ type: 'update', value: entity.documentosOperador, name: 'documentosOperador' });
                return;
            }

            const documentosRequeridos = value.tipoEmpleado.documentosRequeridos.filter(e => (e.id !== 2));
            //actualiza en la lista de documentos los que son requeridos
            const documentosGenerales = entity.documentosGenerales.map(e =>
            ({
                ...e,
                tipoDocumento:
                {
                    ...e.tipoDocumento,
                    requeridoOoerador: false,
                    requerido: documentosRequeridos.findIndex(d => d.id === e.tipoDocumento.id) >= 0
                },
            }));
            dispatch({ type: 'update', value: documentosGenerales, name: 'documentosGenerales' });

            const documentosOperador = entity.documentosOperador.map(e =>
            ({
                ...e,
                tipoDocumento:
                {
                    ...e.tipoDocumento,
                    requeridoOoerador: documentosRequeridos.findIndex(d => d.id === e.tipoDocumento.id) >= 0,
                    requerido: documentosRequeridos.findIndex(d => d.id === e.tipoDocumento.id) >= 0
                },
            }));
            dispatch({ type: 'update', value: documentosOperador, name: 'documentosOperador' });
        }
        dispatch({ type: 'update', value, name });
    };
    return (
        <>
            <div className="form-container full-height">
                {entity?.id > 0 &&
                    <h6 className="col-8"><b>Empleado: </b> {entity?.datosGenerales?.nombre + " " + entity?.datosGenerales?.apellidoPaterno + " " + entity?.datosGenerales?.apellidoMaterno}</h6>
                }
                <TabView activeIndex={0}>
                    <TabPanel header="GENERALES" rightIcon={(errors?.datosGenerales || errors?.asignacion) ? 'pi pi-exclamation-circle' : ''}>
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <Generales generales={entity.datosGenerales}
                                asignacion={entity.asignacion}
                                codigoEmpleado={entity.codigoEmpleado}
                                onChange={update}
                                name="datosGenerales"
                                errorsDatosGenerales={errors?.datosGenerales}
                                errorsAsignacion={errors?.asignacion} />
                        </ReadOnlyProvider>
                    </TabPanel>
                    <TabPanel header="DATOS PERSONALES" rightIcon={(errors?.datosPersonales || errors?.familiares) ? 'pi pi-exclamation-circle' : ''}>
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <DatosPersonales datosPersonales={entity.datosPersonales}
                                familiares={entity.familiares}
                                errors={errors ?? {}}
                                onChange={update} name="datosPersonales"
                                soloLectura={soloLectura} />
                        </ReadOnlyProvider>
                    </TabPanel>
                    <TabPanel header="NÓMINA" rightIcon={errors?.nomina ? 'pi pi-exclamation-circle' : ''}>
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <Nomina nomina={entity.nomina} dataTipoEmpleado={entity.asignacion?.tipoEmpleado}  modoCajaahorro={modoCajaahorro}
                                onChange={update}
                                name="nomina"
                                errors={errors?.nomina ?? {}} />
                        </ReadOnlyProvider>
                    </TabPanel>
                    <TabPanel header="IMSS" rightIcon={errors?.seguridadSocial ? 'pi pi-exclamation-circle' : ''}>
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <IMMS seguridadSocial={entity.seguridadSocial} dataTipoEmpleado={entity.asignacion?.tipoEmpleado}
                                onChange={update}
                                name="seguridadSocial"
                                errors={errors?.seguridadSocial ?? {}} />
                        </ReadOnlyProvider>
                    </TabPanel>
                    <TabPanel header="ARCHIVOS" rightIcon={(errors?.documentosGenerales || errors?.documentosOperador || errors?.licencias || errors?.archivosAdicionales) ? 'pi pi-exclamation-circle' : ''}>
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <Archivos empleado={entity}
                                onChange={update}
                                name="documentos"
                                foto={entity?.foto}
                                errors={errors ?? {}}
                                archivosAdicionales={entity?.archivosAdicionales}
                                documentosGenerales={entity?.documentosGenerales}
                                documentosOperador={entity?.documentosOperador}
                                soloLectura={soloLectura} />
                        </ReadOnlyProvider>
                    </TabPanel>
                    <TabPanel header="HISTORIAL" rightIcon={errors?.antidoping || errors?.actasAdministrativas ? 'pi pi-exclamation-circle' : ''}>
                        <Historial antidoping={entity.antidoping}
                            actasAdministrativas={entity?.actasAdministrativas ?? []}
                            historial={entity.historial}
                            name="antidoping"
                            onChange={update}
                            errors={errors ?? {}}
                            recursosConfiables={entity?.recursosConfiables ?? []}
                            soloLectura={soloLectura}
                            permisoHistorialEmpleados={permisoHistorialEmpleados}
                            bitacora={entity?.empleadoBitacoras} />
                    </TabPanel>
                    <TabPanel header="EQUIPOS">
                        <EquiposEmpleado
                            equipos={entity?.asignacionEquipos}

                            name="equiposEmpleado"
                        />
                    </TabPanel>
                    {(permisoVerCapacitaciones &&       
                    <TabPanel header="CAPACITACIONES">
                        <CapacitacionesEmpleados
                            capacitaciones={entity?.capacitacionesEmpleados}

                            name="capacitacionesEmpleados"
                        />
                    </TabPanel>
                     )}
                    <TabPanel header="OTROS">
                        <Descuentos
                            liquidacion={entity}
                            descuentos={entity}
                            esOperador={true} />
                    </TabPanel>
                    {(permisoCajaAhorro &&            
                    <TabPanel header="RETIROS CAJA AHORRO">
                        <HistorialRetiroCajaAhorro
                            historial={entity?.retiroCajaAhorro}
                             />
                    </TabPanel>
                    )}
                    {(permisoPrestamoCajaAhorro &&            
                    <TabPanel header="ABONOS">
                        <AbonoAhorroHistorial
                            historial={entity?.abono}
                             />
                    </TabPanel>
                    )}
                </TabView>
            </div>
            <div className="form-footer">
                {!soloLectura &&
                    <>
                        {entity.estatus === constantes.estatus.empleados.alta && <BajaEmpleado onBaja={onUpdateStatus} empleado={entity} />}
                        {entity.estatus === constantes.estatus.empleados.baja && <ReIngresoEmpleado onReingreso={onUpdateStatus} empleado={entity} />}
                        {entity.estatus === constantes.estatus.empleados.enProceso &&
                            <Button label={'Dar de Alta'} type="button" onClick={darDeAlta} />
                        }
                    </>
                }
                {(!soloLectura || permisoHistorialEmpleados) &&
                    <Button label="Guardar" className="p-button-outlined" type="button" onClick={()=>save(false)} />
                }
                {(permisoCajaAhorro &&            
                <>
                    {entity.nomina?.ahorro>0 && <Retirocajaahorro onRetiro={onUpdateStatus} empleado={entity} />}
                
                </>
                )}
                 {(permisoPrestamoCajaAhorro &&            
                <>
                    {entity.nomina?.saldoPrestamoahorro<0 && <Abonoahorro onRetiro={onUpdateStatus} empleado={entity} />}
                
                </>
                )}
            </div>
        </>
    )
};


export default DetalleEmpleado;