/* eslint-disable react-hooks/exhaustive-deps */
import alerts from "../../../shared/alerts";
import { Button } from "primereact/button";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import React, { useEffect, useReducer } from "react";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import useApiLlantas from "../../../api/taller/useApiLlantas";
import llantaValidacionEsquema from "./llantaValidacionEsquema";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import constantes from "../../constantes";
import FieldInputMask from "../../../shared/controls/fieldInputMask";
import { TabPanel, TabView } from "primereact/tabview";
import LLantasHistorial from "./llantasHistorial";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import ReadOnlyProvider from "../../../shared/forms/ReadOnlyProvider";

const DetalleLlanta = ({ onSave, id, close, soloLectura }) => {
    const api = useApiLlantas();

    const defaultEntity = () => ({
        entity: { id: 0, posicion: {} },
    });

    const [{ entity, errors, ...state }, dispatch] = useReducer(formReducer, {}, defaultEntity);

    useEffect(() => {
        let timer1 = setTimeout(load, 100);
        return () => {
            clearTimeout(timer1);
        };
    }, [id]);

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.validate(entity);
        }
    }, [entity]);

    const load = async () => {
        let loadedEntity = { entity: await api.obtener(id ?? 0) };
        loadedEntity.entity.posicion = loadedEntity.entity.posicion === 0 ? {} : loadedEntity.entity.posicion;

        dispatch({ type: "reset", getDefault: () => loadedEntity ?? entity });
    };

    const update = (value, propertyName) => {
        dispatch({ type: "update", value: value, name: propertyName });
    };

    const actualizarUnidad = (value, propertyName) => {
        update(value, propertyName);
        update(null, "posicion");
    };

    const formHandler = new FormHandler({
        validationSchema: llantaValidacionEsquema.guardar,
        api: api,
        dispatch,
    });

    const save = async () => {
        const savedEntity = await formHandler.save(entity, true);
        if (formHandler.valdation.result === true) {
            onSave();
        }
        return savedEntity;
    };

    const actualizar = async () => {
        let actualizar = {
            idCautin: entity.idCautin,
            estatus: entity.estatus,
            costo: entity.costo,
            dot: entity.dot,
            kilometros: entity.kilometros,
            profundidad: entity.profundidad,
            marca: {
                id: entity.marca.id,
                nombre: entity.marca.nombre,
            },
        };
        await api.actualizar(entity.id, actualizar);
        onSave(true);
    };

    const asignar = async () => {
        formHandler.setValidacionSchema(llantaValidacionEsquema.asignar);
        const { result } = await formHandler.validate(entity);
        if (result === true) {
            let llantaId = entity.id;
            const asignacion = {
                posicion: entity.posicion.id,
                unidad: entity.unidad,
            };
            if (llantaId === 0) {
                const nuevaLlanta = await formHandler.save(entity);
                llantaId = nuevaLlanta.id;
            }
            await api.asignar(llantaId, asignacion);
            onSave(true);
        }
    };

    const mandarADesecho = async () => {
        if ((await alerts.preguntarSiNo("¿Desea confirmar enviar a desecho?")) === true) {
            await api.enviarDesecho(entity.id);
            onSave(true);
        }
    };

    const mandarAAlmacen = async () => {
        if ((await alerts.preguntarSiNo("¿Desea confirmar mandar a almacen?")) === true) {
            await api.enviarAlmacen(entity.id);
            onSave(true);
        }
    };

    const enviarARenovar = async () => {
        if ((await alerts.preguntarSiNo("¿Desea confirmar enviar a renovar?")) === true) {
            const item = {
                llantaId: entity.id,
            };
            await api.renovar(item);
            onSave(true);
        }
    };

    return (
        <>
            <div className="detalle-llanta form-container full-height">
                <TabView activeIndex={0}>
                    <TabPanel header="GENERALES">
                        <ReadOnlyProvider defaultReadOnly={soloLectura}>
                            <div className="form-row">
                                {!entity.esCautinExterno && (
                                    <FieldInputMask
                                        name="idCautin"
                                        maskVariable={"ABC-99999"}
                                        label="ID (Cautin)"
                                        colMd={6}
                                        disabled={entity.id > 0}
                                        errors={errors}
                                        value={entity.idCautin}
                                        onChange={update}
                                    />
                                )}
                                {entity.esCautinExterno && (
                                    <FieldTextBox
                                        name="idCautin"
                                        label="ID (Cautin) Externo"
                                        colMd={6}
                                        disabled={entity.id > 0}
                                        errors={errors}
                                        value={entity.idCautin}
                                        onChange={update}
                                        maxLength={20}
                                    />
                                )}
                                <FieldCheckBox
                                    name="esCautinExterno"
                                    label="Cautin externo"
                                    value={entity.esCautinExterno}
                                    disabled={entity.id > 0}
                                    onChange={update}
                                    className="py-1"
                                    colMd={3}
                                />
                                <h5 className="col-12">Especificaciones de Llanta</h5>
                                <FieldDropDown
                                    required
                                    name="marca"
                                    label="Marca"
                                    source="marcasLlanta"
                                    colMd={6}
                                    errors={errors}
                                    disabled={entity?.estatus === constantes.estatusLlanta.enDesecho || soloLectura}
                                    value={entity.marca}
                                    onChange={update}
                                />

                                <FieldTextBox
                                    name="modelo"
                                    label="Modelo"
                                    colMd={6}
                                    disabled={entity.id > 0}
                                    errors={errors}
                                    value={entity.modelo}
                                    onChange={update}
                                />

                                <FieldInputNumber
                                    required
                                    name="costo"
                                    label="Costo"
                                    colMd={6}
                                    errors={errors}
                                    fractionDigits={2}
                                    disabled={entity?.estatus === constantes.estatusLlanta.enDesecho || soloLectura}
                                    value={entity.costo}
                                    onChange={update}
                                />

                                <FieldDropDown
                                    required
                                    name="estado"
                                    label="Estado"
                                    source="general/Estado llanta"
                                    colMd={6}
                                    disabled={entity.id > 0}
                                    errors={errors}
                                    value={entity.estado}
                                    onChange={update}
                                />

                                <FieldInputNumber
                                    name="costoRenovacion"
                                    label="Costo de Renovación"
                                    colMd={6}
                                    disabled={true}
                                    value={entity?.costoRenovacion}
                                    onChange={update}
                                    fractionDigits={2}
                                />

                                <FieldDropDown
                                    required
                                    name="tamano"
                                    label="Tamaño"
                                    source="general/tamaño Llanta"
                                    colMd={6}
                                    disabled={entity.id > 0}
                                    errors={errors}
                                    value={entity.tamano}
                                    onChange={update}
                                />

                                <FieldTextBox
                                    required
                                    name="dot"
                                    label="DOT"
                                    colMd={6}
                                    errors={errors}
                                    maxLength={16}
                                    value={entity.dot}
                                    onChange={update}
                                    disabled={entity?.estatus === constantes.estatusLlanta.enDesecho || soloLectura}
                                />

                                <FieldDropDown
                                    required
                                    name="tipoLLanta"
                                    label="Tipo LLanta"
                                    source="general/TipoLLanta"
                                    colMd={6}
                                    disabled={entity.id > 0}
                                    errors={errors}
                                    value={entity.tipoLLanta}
                                    onChange={update}
                                />

                                <FieldInputNumber
                                    required
                                    name="profundidad"
                                    label="Profundidad (Mm)"
                                    colMd={6}
                                    errors={errors}
                                    fractionDigits={2}
                                    max={10}
                                    disabled={entity?.estatus === constantes.estatusLlanta.enDesecho || soloLectura}
                                    value={entity.profundidad}
                                    onChange={update}
                                />

                                <h5 className="col-12">Asignación de Llanta</h5>

                                {entity.estatus === constantes.estatusLlanta.enUso ||
                                entity.estatus === constantes.estatusLlanta.enDesecho ||
                                entity.estatus === constantes.estatusLlanta.envioRenovado ? (
                                    <>
                                        <FieldTextBox
                                            disabled={true}
                                            value={entity.unidad?.nombre}
                                            label="No Económico"
                                            colMd={6}
                                        />
                                        <FieldTextBox
                                            disabled={true}
                                            value={entity.unidad?.tipoUnidad}
                                            label="Tipo Unidad"
                                            colMd={6}
                                        />
                                        <FieldTextBox
                                            disabled={true}
                                            value={entity.posicion}
                                            label="Posición"
                                            colMd={6}
                                        />
                                        <FieldInputNumber
                                            required
                                            name="kilometros"
                                            label="Kilometros"
                                            colMd={6}
                                            errors={errors}
                                            fractionDigits={2}
                                            disabled={true}
                                            value={entity.kilometros}
                                            onChange={update}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FieldAutoComplete
                                            required
                                            name="unidad"
                                            label="No Económico"
                                            source="unidades"
                                            colMd={6}
                                            errors={errors}
                                            params={{ todoLosEstatus: false, enProceso: true,  todasLasEmpresas: true }}
                                            value={entity.unidad}
                                            onChange={actualizarUnidad}
                                        />
                                        <FieldTextBox
                                            name="tipoUnidad"
                                            disabled={true}
                                            value={entity.unidad?.tipoUnidad}
                                            label="Tipo Unidad"
                                            colMd={6}
                                        />
                                        <FieldDropDown
                                            required
                                            name="posicion"
                                            label="Posición"
                                            source="posiciones"
                                            params={entity.unidad}
                                            colMd={6}
                                            errors={errors}
                                            value={entity.posicion}
                                            onChange={update}
                                        />
                                        <FieldInputNumber
                                            required
                                            name="kilometros"
                                            label="Kilometros"
                                            colMd={6}
                                            errors={errors}
                                            fractionDigits={2}
                                            value={entity.kilometros}
                                            onChange={update}
                                        />
                                    </>
                                )}
                            </div>
                        </ReadOnlyProvider>
                        <div className="d-flex justify-content-center mt-3">
                            {entity.unidad != null && (
                                <img
                                    src={`../../../img/unidades/TipoId${entity.unidad?.tipoUnidadId}_${
                                        entity.posicion?.id ?? ""
                                    }.png`}
                                    height={150}
                                    alt="posicion"
                                />
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel
                        headerStyle={entity.id === 0 ? { display: "none" } : { display: "inline" }}
                        header="HISTORIAL"
                    >
                        <LLantasHistorial historial={entity?.historialLlantas} />
                    </TabPanel>
                </TabView>
            </div>
            <div className="form-footer">
                {!soloLectura && (
                    <>
                        {(entity.estatus === constantes.estatusLlanta.enUso ||
                            entity.estatus === constantes.estatusLlanta.enAlmacen) && (
                            <>
                                <Button
                                    label="Mandar a Desecho"
                                    className="p-button-outlined"
                                    type="button"
                                    onClick={mandarADesecho}
                                />
                            </>
                        )}
                        {(entity.estatus === constantes.estatusLlanta.enUso ||
                            entity.estatus === constantes.estatusLlanta.renovado) && (
                            <Button label="Guardar en Almacén" type="button" onClick={mandarAAlmacen} />
                        )}
                        {entity.id > 0 && entity?.estatus !== constantes.estatusLlanta.enDesecho && (
                            <Button label="Actualizar" type="button" onClick={() => actualizar()} />
                        )}
                        {(entity.estatus === constantes.estatusLlanta.enAlmacen ||
                            entity.estatus === constantes.estatusLlanta.renovado) && (
                            <Button label="Asignar" type="button" onClick={asignar} />
                        )}
                        {entity.id === 0 && <Button label="Guardar" type="button" onClick={() => save(null)} />}
                        {(entity.estatus === constantes.estatusLlanta.enAlmacen ||
                            entity.estatus === constantes.estatusLlanta.enUso) && (
                            <Button
                                label="Enviar a Renovar"
                                type="button"
                                className="p-button-outlined"
                                onClick={enviarARenovar}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default DetalleLlanta;
