/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import "./EstatusDiarios.css";
import moment from "moment";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import * as numeral from "numeral";
import DiaEstatus from "./DiaEstatus";
import useDias from "./useDias";
import useUnidades from "./useUnidades";
import Filtro from "../../shared/componentes/filtro";
import {useLoadingDispatch} from "../../layout/show-loading-context";
import useApiEstatusDiarios from "../../api/reportes/useEstatusDiariosApi";
import EditModal from "./EditModal";
import AgregarUnidadModal from "./AgregarUnidadModal";
import EstatusDiarioUnidad from "./EstatusDiarioUnidad";
import EstatusDiarioDia from "./EstatusDiarioDia";
import useSeguridad from "../../Seguridad/useSeguridad";
import constantes from "../../catalogos/constantes";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import ExportarModal from "./ExportarModal";
import {useNavigate} from 'react-router-dom'
import {IoPersonSharp} from "react-icons/io5";
import UnidadesNegocio from "./UnidadesNegocio";
import Filtros from "./Filtros";
import {useEmpresa} from "../../layout/EmpresaProvider";

const EstatusDiarios = () => {

    const api = useApiEstatusDiarios();
    const dispatch = useLoadingDispatch();
    const history = useNavigate();

    const [visibleExportar, setVisibleExportar] = useState(false)
    const [unidadDiaActual, setUnidadDiaActual] = useState(null);
    const {dias, iniciar, actualizar} = useDias();
    const [openModalAgregarUnidad, setOpenModalAgregarUnidad] = useState(false);
    const [visibleTotales, setVisibleTotales] = useState(true)
    const [filtroActual, setFiltroActual] = useState({})

    const {hideEmpresa} = useEmpresa();

    useEffect(() => {
        hideEmpresa();
    }, []);

    const editable = useSeguridad().esEditable(constantes.menus.estatusDiarios);

    const {
        cambiarFiltro,
        unidadesSeleccionadas,
        setUnidades,
        filtroUnidades,
        countUnidades,
        setOtrasUnidades,
        otrasUnidadesSeleccionadas,
    } = useUnidades();

    const buscar = async (filtro) => {
        dispatch({type: "show"});
        const params = {
            ...filtro,
            unidadIds: filtro.unidadIds?.join(","),
        };
        const result = await api.filtrarPost(params);
        //distinct de los municipios a tra vez de un Set, ya que el set solo agrega valores únicos
        setUnidades(result.unidades);

        var isDefault = result.otrasUnidades.filter((e) => e.isDefault === true).length;
        if (isDefault === 0) result.otrasUnidades.push({isDefault: true});

        setOtrasUnidades(result.otrasUnidades);

        dispatch({type: "hide"});
        iniciar(result.dias);
    };

    const getStyle = () => {
        return {
            gridTemplateRows:
                unidadesSeleccionadas?.length + otrasUnidadesSeleccionadas?.length > 0
                    ? `140px repeat(${unidadesSeleccionadas.length + otrasUnidadesSeleccionadas.length}, 70px)`
                    : "190px",
        };
    };

    const onUpdateDatos = async (fecha, datos) => {
        await api.actualizar({...datos, fecha: moment(fecha)._d, motivoId: datos.motivo?.id});
        actualizar(fecha, datos);
        setUnidadDiaActual(null);
    };

    const filtrarPorEstatus = (dia, campo, valor) => {
        cambiarFiltro(dia, campo, valor);
    };

    const handleFiltrar = (filtro)=>{
        const nuevoFiltro ={...filtroActual, ...filtro}
        buscar(nuevoFiltro);
        setFiltroActual(nuevoFiltro)
    }

    const onChangeUnidadNegocioId = (unidadNegocioId)=>{
        const nuevoFiltro ={...filtroActual, unidadNegocioId}
        buscar(nuevoFiltro);
        setFiltroActual(nuevoFiltro)
    }

    return (
        <>
            <Filtro
                filtroKey={"EstatusDiarios"}
                styleForm={{height: "40px"}}
                defaultValue={{fecha: moment().format("YYYY-MM-DD")}}
                handleSubmit={handleFiltrar}
                classButtons="field-iconos btn-group"
            >

                <>
                    <ButtonIcon title={"Grafica"} onClick={() => history(`/reportes/estatus_diario-grafica`)}
                                iconName={'FaChartBar'}/>
                    <ButtonIcon title={"Exportar"} onClick={() => {
                        setVisibleExportar(true)
                    }} iconName={'FaFileExcel'}/>
                </>
                <Filtros/>
            </Filtro>

            <div className="g-diaria">
                <div className="calendario" style={getStyle()}>
                    <div className="header-unidad">
                        <span
                            style={{cursor: 'pointer', paddingTop: '5px', display: 'flex', justifyContent: 'flex-end'}}>
                            <i className={`pi ${visibleTotales ? 'pi-minus-circle' : 'pi-plus-circle'}`}
                               style={{'fontSize': '1.5em'}}
                               onClick={() => setVisibleTotales(!visibleTotales)}>
                            </i>
                        </span>
                        <UnidadesNegocio unidadNegocioId={filtroActual.unidadNegocioId} onChange={onChangeUnidadNegocioId} />
                        <div className={'unidades-count'}>
                            Unidades <span>({countUnidades})</span>
                        </div>
                    </div>
                    {unidadesSeleccionadas.map((unidad, index) =>
                        EstatusDiarioUnidad(
                            index,
                            unidad,
                            unidadesSeleccionadas.length,
                            setOpenModalAgregarUnidad,
                            false,
                            editable
                        )
                    )}

                    {otrasUnidadesSeleccionadas.map((unidad, index) =>
                        EstatusDiarioUnidad(
                            index,
                            unidad,
                            otrasUnidadesSeleccionadas.length,
                            setOpenModalAgregarUnidad,
                            true,
                            editable
                        )
                    )}

                    {/*Pinta el calendario de días por unidad*/}
                    {dias.map((dia, index) => (
                        <React.Fragment key={index}>
                            <div className="header-diarios">
                                <div className="facturado">
                                    {(dia.facturado != null && visibleTotales) ? numeral(dia.facturado).format("$0,0") : " "}
                                </div>
                                <div className="estatus-count-dias">
                                    <DiaEstatus dia={dia} filtro={filtroUnidades} onClick={filtrarPorEstatus}/>
                                </div>
                                <div className={`day-name`}>
                                    <div style={{
                                        position: "absolute",
                                        top: '4px',
                                        left: '12px',
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <span style={{
                                            marginRight: '3px',
                                            fontSize: '1.15em',
                                            fontWeight: 'bold',
                                            color: 'rgb(255, 192, 0)'
                                        }}>
                                            {dia.posturaCount}
                                        </span>
                                        <IoPersonSharp
                                            className={`filtro-postura ${filtroUnidades.postura && filtroUnidades.fecha === dia.fecha ? 'seleccionado' : ''}`}
                                            fill={'rgb(255, 192, 0)'}
                                            cursor={"pointer"}
                                            onClick={() => filtrarPorEstatus(dia, 'postura', filtroUnidades.postura ? null : true)}/>
                                    </div>

                                    <span className={dia.esDiaActual ? "font-blue bold" : ""}>
                                    {dia.esDiaActual
                                        ? `Hoy ${moment(dia.fecha).format("D MMM")}`
                                        : moment(dia.fecha).format("dddd, D MMM")}
                                    </span>
                                    <div style={{
                                        position: "absolute",
                                        top: '4px',
                                        right: '12px',
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <span style={{
                                            marginRight: '3px',
                                            fontSize: '1.15em',
                                            fontWeight: 'bold',
                                            color: 'red'
                                        }}>
                                            {dia.sinOperadorCount}
                                        </span>
                                        <IoPersonSharp
                                            className={`filtro-sin-operador ${filtroUnidades.sinOperador && filtroUnidades.fecha === dia.fecha ? 'seleccionado' : ''}`}
                                            fill={'red'}
                                            cursor={"pointer"}
                                            onClick={() => filtrarPorEstatus(dia, 'sinOperador', filtroUnidades.sinOperador ? null : true)}/>
                                    </div>

                                </div>
                            </div>
                            {unidadesSeleccionadas.map((unidad, unidadIndex) => {
                                return EstatusDiarioDia(
                                    unidad,
                                    unidadIndex,
                                    unidadesSeleccionadas.length,
                                    false,
                                    setUnidadDiaActual,
                                    dia,
                                    dias,
                                    index,
                                    onUpdateDatos,
                                    false,
                                    editable
                                );
                            })}

                            {otrasUnidadesSeleccionadas.map((unidad, unidadIndex) => {
                                return EstatusDiarioDia(
                                    unidad,
                                    unidadIndex,
                                    otrasUnidadesSeleccionadas.length,
                                    true,
                                    setUnidadDiaActual,
                                    dia,
                                    dias,
                                    index,
                                    onUpdateDatos,
                                    true,
                                    editable
                                );
                            })}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <EditModal
                visible={unidadDiaActual != null}
                fecha={unidadDiaActual?.fecha}
                esDiaActual={false}
                unidaDia={unidadDiaActual}
                onHide={() => setUnidadDiaActual(null)}
                onUpdateDatos={onUpdateDatos}
            />
            <ExportarModal
                visible={visibleExportar}
                onHide={() => setVisibleExportar(false)}
            />

            <AgregarUnidadModal
                visible={openModalAgregarUnidad}
                actualizar={() => buscar({fecha: moment().format("YYYY-MM-DD")})}
                onHide={() => setOpenModalAgregarUnidad(false)}
                onUpdateDatos={onUpdateDatos}
            />
        </>
    );
};


export default EstatusDiarios;
