/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "primereact/dialog";
import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import useApiIncidencia from "../../../api/administracion/useApiIncidencia";
import useForm from "../../../shared/forms/useForm";
import { useState } from "react";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { serialize } from "object-to-formdata";
import produce from "immer";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";

const initialValues = {
    fechaInicio: new Date()
};

const Cobro = ({ incidencia, visible, onClose, onSaved }) => {
    const api = useApiIncidencia();
    const [submitted, setSubmitted] = useState(false);
    const {
        values: cobro,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);

    useEffect(() => {
        const newValue = produce(incidencia ?? {}, draft => {
            draft.saldo += draft?.cargos?.filter(c => !c.cobrado)?.reduce((a, b) => a + (b?.costo ?? 0), 0) ?? 0;
            draft.saldoAnterior = draft.saldo;
        });

        setValues(newValue);
    }, [incidencia]);

    const onSend = async () => {
        setSubmitted(true);

        if (!isValid)
            return;

        const formData = serialize(cobro ?? {}, { indices: true, dotsForObjectNotation: true });

        await api.enviarCargo(formData);

        onSaved();
    };


    const renderFooterModal = () => {
        return (
            <div className="p-1">
                <Button label="Cancelar" onClick={onClose} className="p-button-outlined" />
                <Button label="Enviar Cobro" onClick={onSend} type="button" />
            </div>
        );
    };

    return (
        <Dialog header="Cobrar" visible={visible} style={{ width: '50%' }}
            onHide={onClose} footer={renderFooterModal}>
            <Form columns={2} values={cobro} onChange={onChange} onValidate={onValidate}
                submitted={submitted}>
                <Field label={'Fecha de Inicio'} field={'fechaInicio'} render={FormCalendar} required={true}
                    disabled={cobro?.cargos?.some(c => c.cobrado)} />
                <Field label={'Número de Exhibiciones'} field="exhibiciones" required={true}
                    render={({ onParentChange, parent, ...props }) => <FormInputNumber {...props}
                        onChange={(exhibiciones) =>
                            onParentChange({
                                exhibiciones,
                                parcialidad: parent?.saldo / exhibiciones
                            })} />} />
                <Field label={'Costo'} field={'costo'} required={true} disabled={true} render={FormInputNumber} />
                <Field label={'Saldo'} field={'saldo'} render={FormInputNumber} required={true} disabled={true} />
                <Field label={'Descuento'} field={'descuento'} required={true}
                    render={({ onParentChange, parent, ...props }) => <FormInputNumber {...props}
                        onChange={(descuento) =>
                            onParentChange({
                                descuento,
                                saldo: parent?.saldoAnterior - descuento,
                                parcialidad: (parent?.saldoAnterior - descuento) / parent?.exhibiciones
                            })} />} />
                <Field label={'Parcialidad'} field={'parcialidad'} required={true} disabled={true} render={FormInputNumber} />
                <Field label={'Comentarios'} field={'comentarios'} required={true} render={FormTextArea} cols={2} rows={5} maxLength={800} />
            </Form>
        </Dialog >
    );
};

export default Cobro;