/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import useApiViajeSeguimiento from "../../../../api/operaciones/useApiViajesSeguimiento";
import formReducer from "../../../../shared/forms/formReducer";
import { TabPanel, TabView } from "primereact/tabview";
import FieldCheckBox from "../../../../shared/controls/fieldCheckBox";
import moment from "moment";
import SeguimientoSegmento from "./segmento";
import { Button } from "primereact/button";
import produce from "immer";
import alerts from "../../../../shared/alerts";
import Notificaciones from "../notificaciones";
import RutasViajeEdi from "./rutasViajeEdi";
import GeocercasViaje from "./geocercasViaje";
import constantesOperacion from "../../../constantes";
import PdfViewer from "../../../../shared/componentes/pdfViewer";
import { Dialog } from "primereact/dialog";

const DetalleSeguimiento = ({ viajeId, onSave, close, soloLectura }) => {
    const api = useApiViajeSeguimiento();
    const estatus = constantesOperacion.estatusViajes;

    const defaultEntity = () => ({ entity: {} });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [segmentoEnCurso, setSegmentoEnCurso] = useState(null);
    const [pdfCartaPorteVisible, setPdfCartaPorteVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [otrosViajesconMismaUnidad, setOtrosViajesconMismaUnidad] = useState([]);
    const [esViajeaIniciar, setEsViajeaIniciar] = useState(false);

    const [numeroEconomico, setNumeroEconomico] = useState("");

    useEffect(() => {
        let timer = setTimeout(null, 0);

        async function cargar() {
            const result = await api.obtener(viajeId);
            if (
                result.segmentos.length > 0 &&
                (result.segmentos.some((e) => e.enCurso) || result.segmentos.some((e) => e.segmentado))
            ) {
                const indexEnCurso = result.segmentos.findIndex((e) => e.enCurso);
                let indexSegmentado = -1;
                result.segmentos.forEach((element) => {
                    if (element.segmentado) indexSegmentado++;
                });
                const index = indexEnCurso >= 0 ? indexEnCurso : indexSegmentado;
                const esUltimoSegmento = index + 1 === result.segmentos.length;
                setSegmentoEnCurso({ ...result.segmentos[index], index, esUltimoSegmento });
            }

            dispatch({ type: "update", value: result });
        }

        cargar().then();

        return () => clearTimeout(timer);
    }, []);

    const actualizar = (value, propertyName) => {
        dispatch({ type: "update", value, name: propertyName });
    };

    const actualizarSegmento = (value, propertyName, index) => {
        const newValue = produce(entity.segmentos, (draft) => {
            draft[index][propertyName] = value;

            //Solo puede haber un segmento en curso
            if (propertyName === "enCurso") {
                draft.forEach((e, i) => {
                    if (i !== index) {
                        e.enCurso = false;
                    }
                });
            }
        });

        dispatch({ type: "update", value: newValue, name: "segmentos" });
    };

    const enRuta = 122;

    const guardar = async () => {
        let viaje = entity.segmentos.length === 0 ? entity : entity.segmentos.find((e) => e.enCurso);

        viaje = { ...viaje, aplicaEdi: entity.aplicaEdi, rutasEdi: entity.rutasEdi };
        if (!viaje?.enCurso || viaje.incidencias == null || viaje.incidencias.length === 0) {
            alerts.aviso("El viaje debe estar en curso y debe capturar por lo menos un comentario");
        } else {
            var incidenciaEsRuta = viaje.incidencias.find(
                (e) => e.esSeguimiento && e.esNueva && e.estatus.id === enRuta
            );

            if (incidenciaEsRuta === undefined) {
                guardarSegmento(viaje);
            } else {
                // var cartasPorte = await api.otrosViajesEnRuta({ viajeId: viaje.id, unidadId: viaje.unidadId });

                var listaViajesEnRuta = await api.otrosViajesEnRutaPorUnidad({
                    viajeId: viaje.id,
                    unidadId: viaje.unidadId,
                });
                if (listaViajesEnRuta.length === 0) {
                    guardarSegmento(viaje);
                } else {
                    setOtrosViajesconMismaUnidad(listaViajesEnRuta);
                    setVisibleModal(true);
                    setEsViajeaIniciar(false);
                    setNumeroEconomico(viaje.unidad);
                }
            }
        }
    };

    const guardarSegmento = async (viaje) => {
        await api.actualizar(viaje.id, viaje);
        onSave();
    };

    const iniciar = async () => {
        const viaje = entity.segmentos.length === 0 ? entity : entity.segmentos[0];
        // var cartasPorte = await api.otrosViajesEnRuta({ viajeId: viaje.id, unidadId: viaje.unidadId });
        var listaViajesEnRuta = await api.otrosViajesEnRutaPorUnidad({ viajeId: viaje.id, unidadId: viaje.unidadId });
        if (listaViajesEnRuta.length === 0) {
            await api.iniciar(viaje.id, viaje);
            onSave();
        } else {
            setOtrosViajesconMismaUnidad(listaViajesEnRuta);
            setVisibleModal(true);
            setEsViajeaIniciar(true);
            setNumeroEconomico(viaje.unidad);
        }
    };

    const iniciarRetorno = async () => {
        await api.iniciarRetorno(entity.id);
        onSave();
    };

    const terminar = async () => {
        if (await alerts.preguntarSiNo("¿Desea cerrar el viaje?")) {
            const viaje = entity.segmentos.length === 0 ? entity : entity.segmentos[entity.segmentos.length - 1];
            await api.terminar(viaje.id, viaje);

            onSave();
        }
    };

    const terminarViajesAntiguos = async (viajeId, index) => {
        if (await alerts.preguntarSiNo("¿Desea cerrar el viaje?")) {
            await api.terminar(viajeId);
            const newList = produce(otrosViajesconMismaUnidad, (draft) => {
                draft.splice(index, 1);
            });

            setOtrosViajesconMismaUnidad(newList);
        }
    };

    const regresaraEnRuta = async () => {
        await api.regresaraEnRuta(entity.id);
        onSave();
    };

    const regresaraProgramado = async () => {
        await api.regresaraProgramado(entity.id);
        onSave();
    };

    const descargarCartaPorte = async () => {
        await api.descargar(viajeId, entity.cartaPorte);
        setPdfCartaPorteVisible(false);
    };

    const IniciarViajeModal = async () => {
        setVisibleModal(false);

        if (esViajeaIniciar) {
            const viaje = entity.segmentos.length === 0 ? entity : entity.segmentos[0];
            await api.iniciar(viaje.id, viaje);
            onSave();
        } else {
            let viaje = entity.segmentos.length === 0 ? entity : entity.segmentos.find((e) => e.enCurso);
            viaje = { ...viaje, aplicaEdi: entity.aplicaEdi, rutasEdi: entity.rutasEdi };
            guardarSegmento(viaje);
        }
    };

    const renderFooterModal = () => {
        return (
            <div className="p-1">
                <Button
                    className="btn-primary"
                    label={esViajeaIniciar ? "Iniciar Viaje" : "Guardar Segmento"}
                    type="button"
                    onClick={(e) => IniciarViajeModal()}
                    disabled={otrosViajesconMismaUnidad.length >= 3}
                />

                <Button label="Cancelar" onClick={(e) => setVisibleModal(false)} className="p-button-outlined" />
            </div>
        );
    };

    return (
        <>
            <Dialog
                header={""}
                visible={visibleModal}
                style={{ width: "60%", height: "63%" }}
                footer={renderFooterModal()}
                onHide={(e) => setVisibleModal(false)}
            >
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h5 className="bold">Viajes en ruta con la unidad {numeroEconomico}</h5>
                    <table className="table table-borderless">
                        <thead className="thead-light">
                            <tr>
                                <th>Fecha Inicio</th>
                                <th>Fecha Fin</th>
                                <th>Cliente</th>
                                <th>Carta Porte</th>
                                <th>Ruta</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {otrosViajesconMismaUnidad?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="input left">
                                            {moment(item.fechaInicio).format("DD/MM/YYYY hh:mm a")}
                                        </td>
                                        <td className="input left">
                                            {moment(item.fechaFin).format("DD/MM/YYYY hh:mm a")}
                                        </td>
                                        <td className="input">{item.cliente}</td>
                                        <td className="input left">{item.cartaPorte}</td>
                                        <td className="input">{item.ruta}</td>
                                        <td className="input rigth">
                                            <Button
                                                className="btn btn-sm btn-primary"
                                                style={{ backgroundColor: "green" }}
                                                label="Cerrar Viaje"
                                                type="button"
                                                onClick={(e) => terminarViajesAntiguos(item.viajeId, index)}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Dialog>

            <div className="form-row">
                <div className="form-container full-height">
                    <h5 className="col-12">Detalle del Viaje {entity?.cartaPorte}</h5>

                    <div className="col-12 mt-3">
                        <div>
                            <b>{entity.cliente || "VACÍO"}</b>
                        </div>
                        <div>
                            {entity.idViaje != null ? entity.idViaje : ""}
                            {entity.idViajeRetorno != null ? `/ ${entity.idViajeRetorno} (Ret.)` : ""}
                        </div>
                    </div>
                    {entity.registraFechas ? (
                        <RutasViajeEdi viaje={entity} errors={errors} actualizar={actualizar} />
                    ) : (
                        <div
                            style={{ display: "grid", grid: "auto-flow / 1fr 4fr 2fr 2fr", gridGap: "0.3em" }}
                            className="mt-3 mb-2 col-12"
                        >
                            {entity.rutas?.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <div>
                                            <b>{item.nombre}</b>
                                            {index + 1 < entity.rutas.length && (
                                                <div className="pt-2">
                                                    <i className="pi pi-ellipsis-v" />
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <div>
                                                <b>{item.planta}</b>
                                            </div>
                                            <div>{item.direccionCompleta}</div>
                                        </div>
                                        <div>
                                            <FieldCheckBox
                                                className="mt-1"
                                                name="esCarga"
                                                label="Carga"
                                                value={item.esCarga}
                                                disabled
                                            />
                                            <FieldCheckBox
                                                name="desenganchar"
                                                label="Desenganchar"
                                                value={item.desenganchar}
                                                disabled
                                            />
                                            <FieldCheckBox
                                                name="esDescarga"
                                                label="Descarga"
                                                value={item.esDescarga}
                                                disabled
                                            />
                                        </div>
                                        <div>
                                            <div>{moment(item.fecha).format("DD/MM/YYYY")}</div>
                                            <div>{item.hora}</div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                    <TabView className="tab-secondary" activeIndex={0}>
                        <TabPanel header="Seguimiento Unidades">
                            {entity.segmentos?.length === 0 ? (
                                <SeguimientoSegmento
                                    viaje={entity}
                                    actualizar={actualizar}
                                    soloLectura={soloLectura}
                                    segmentos={entity.segmentos}
                                />
                            ) : (
                                entity.segmentos?.map((item, index) => (
                                    <SeguimientoSegmento
                                        viaje={item}
                                        index={index}
                                        onSave={onSave}
                                        segmentos={entity.segmentos}
                                        segmentoEnCurso={segmentoEnCurso}
                                        soloLectura={soloLectura}
                                        actualizar={(value, name) => actualizarSegmento(value, name, index)}
                                    />
                                ))
                            )}
                        </TabPanel>

                        <TabPanel header="Geocercas">
                            <GeocercasViaje viaje={entity} />
                        </TabPanel>

                        <TabPanel header="Notificaciones">
                            <Notificaciones
                                unidadId={entity.unidadId}
                                viajeId={entity.id}
                                width="100%"
                                mostrarComentarios
                                filtro={{ mostrarTodo: true }}
                                soloLectura={soloLectura}
                            />
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <div className="form-footer">
                {entity.sePuedeTerminar && !soloLectura && (
                    <div className="flex-grow-1">
                        <Button className="btn-primary" label="Cerrar Viaje" type="button" onClick={terminar} />
                    </div>
                )}

                {entity.cartaPorte > 0 && entity.estatus !== estatus.cancelado && (
                    <Button
                        label="Descargar carta porte"
                        className="p-button-outlined"
                        type="button"
                        onClick={() => setPdfCartaPorteVisible(true)}
                    />
                )}

                <Button label="Salir" className="p-button-outlined" onClick={close} />
                {!soloLectura && (
                    <>
                        {entity.sePuedeIniciar && (
                            <Button className="btn-primary" label="Iniciar Viaje" type="button" onClick={iniciar} />
                        )}

                        {entity.puedeCambiaraEnRuta && entity.tieneAccesoCambioEstatus && (
                            <div className="flex-grow-1">
                                <Button
                                    className="p-button-outlined p-button-secondary"
                                    label="Regresar a en Ruta"
                                    type="button"
                                    onClick={regresaraEnRuta}
                                />
                            </div>
                        )}
                        {entity.puedeCambiaraEnProgramado && entity.tieneAccesoCambioEstatus && (
                            <div className="flex-grow-1">
                                <Button
                                    className="p-button-outlined p-button-secondary"
                                    label="Regresar a en Programado"
                                    type="button"
                                    onClick={regresaraProgramado}
                                />
                            </div>
                        )}

                        {entity.sePuedeIniciarRetorno && (
                            <Button
                                className="btn-primary"
                                label="Iniciar Retorno"
                                type="button"
                                onClick={iniciarRetorno}
                            />
                        )}

                        {!entity.terminado && <Button label="Guardar" type="button" onClick={guardar} />}
                    </>
                )}
            </div>

            {/*Modal con la vista previa de la carta porte*/}
            {entity.id > 0 && (
                <PdfViewer
                    header="Carta Porte"
                    urlApi={`operaciones/viajes/programacion/${viajeId}/pdfCartaPorte`}
                    descargar={descargarCartaPorte}
                    visible={pdfCartaPorteVisible}
                    hide={() => setPdfCartaPorteVisible(false)}
                />
            )}
        </>
    );
};

export default DetalleSeguimiento;
