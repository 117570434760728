/* eslint-disable react-hooks/exhaustive-deps */
import useApiViajeSeguimientoMonitoreo from "../../../api/operaciones/useApiSeguimientoMonitoreo";
import React, {useCallback, useEffect, useState} from "react";
import useOcultarMostrarViajes from "./useOcultarMostrarViajes";
import useMapa from "./mapas/UseMapa";
import alerts from "../../../shared/alerts";
import useFiltro from "./Filtros/useFiltro";
import {useControlMap} from "./ControlMapProvider";
import {writeToClipboard} from "../../../shared/utility";
import useApiSource from "../../../api/source";
import {filtroOpciones,  tipoIndicador} from "./SeguimentoConstantes";
import {utcToString} from "../../../shared/format";
import constantes from "../../../administracion/constantes";
import _ from "lodash";
import {actualizarColorViaje,  getUnidades} from "./SeguimientoMonitoreutility";

const seguimientoMonitoreoContext = React.createContext(undefined)
let intervalPosicionId = null;
let intervalEtaId = null;
let intervalIndicadorId = null;


const useSeguimientoMonitoreoProvider = () => {
    const api = useApiViajeSeguimientoMonitoreo();
    const apiSource = useApiSource();

    const [viajes, setViajes] = useState([])
    const [viajeIds, setViajeIds] = useState([])
    const [unidades, setUnidades] = useState([])
    const [posiciones, setPosiciones] = useState([])
    const [viajesEnSeguimiento, setViajesEnSeguimiento] = useState([])
    const [ruta, setRuta] = useState({unidadId: null, viajeId: null, puntos: [], marcadores: [], stops: []})
    const [viajeSeleccionado, setViajeSeleccionado] = useState(null)
    const [centrarMapaCount, setCentrarMapaCount] = useState(0)
    const [viajesFiltrados, setViajesFiltrados] = useState([])
    const [usuarioEstaciones, setUsuarioEstaciones] = useState([])
    const [mostrar, setMostrar] = useState(filtroOpciones[0])
    const [contadorUnidades, setContadorUnidades] = useState({})
    const [unidadViajeMap, setUnidadViajeMap] = useState(new Map())

    const filtro = useFiltro()
    const {mostrarSeleccionGeocerca, ocultarSeleccionGeocerca, cerrarRuteo, setVisibleRuteo, setNotificacionSeleccionada} = useControlMap()

    const onMostrarOcultar = () => {
        setRuta({})
    }

    const {
        mostrarOcultar,
        mostrarViajeEnMapa,
        viajeVisible,
        todosVisibles,
        cambiarTodosVisibles,
        unidadesOcultas, viajesOcultos
    } = useOcultarMostrarViajes(viajes, onMostrarOcultar)

    const {mapa, localizar} = useMapa()

    const aplicarFiltro = async (nuevoFiltro, apiMethod) => {
        nuevoFiltro = nuevoFiltro ?? {
            ...filtro.value,
            clienteId: filtro.value.cliente?.id ?? 0,
            municipioOrigenId: filtro.value.municipioOrigen?.id ?? 0,
            municipioDestinoId: filtro.value.municipioDestino?.id ?? 0,
            geocercaId: filtro.value.geocerca?.id ?? 0,
        };
        const result = await api.filtrar(apiMethod??mostrar.api,nuevoFiltro);
        setViajes(result.viajes.map(v => {
            return {...v, indicadores: viajes.find(e => e.viajeId === v.viajeId)?.indicadores}
        }))

        setUnidadViajeMap(new Map(result.viajes.map( e=> [e.unidadId, e] )))

        setContadorUnidades({
            enRuta:result.unidadesEnRuta,
            programadas:result.unidadesProgramados,
            sinViaje: result.unidadesSinViajes,
            enMantenimiento: result.enMantenimiento
        })

        setViajeIds(result.viajes.map(e => {return {viajeId: e.viajeId, unidadId:e.unidadId, cajaId:e.cajaId}}));
        //ordena acsendente
        result.unidades.sort((a, b) => a - b)
        setUnidades(result.unidades)
        setRuta({})
        filtro.ocultarFiltro()
        setNotificacionSeleccionada(null)
    }

    const obtenerEtas = async (viajes) => {

        if (viajes.length <= 0)
            return

        const parameters = {viajeIds: JSON.stringify(viajes.map(e=> e.viajeId))}


        const etas = await api.getEtas(parameters)
        if (etas.error)
            return

        setViajes(v => v.filter(e=>e!=null).map(e => {
            return actualizarColorViaje({...e, eta: agregarEtas(e, etas)})
        }))
    }

    const obtenerIndicadoresViajes = async (viajes) => {

        if (viajes.length <= 0)
            return

        const indicadores = await api.getIndicadoresViajes({viajes})

        setViajes(v => v.filter(e=>e!=null).map(e => {
            return actualizarColorViaje(agregarIndicadoresViajes(e, indicadores))
        }))
    }

    const agregarIndicadoresViajes = (viaje, indicadores) => {
        const indicadorViaje = indicadores.find(e => e.viajeId === viaje.viajeId);
        if (indicadorViaje == null)
            return viaje;

        return {
            ...viaje,
            indicadores: {...viaje.indicadores, ...indicadorViaje.indicadores},
            horasDescanso: indicadorViaje.horasDescanso
        }
    }

    const agregarEtas = (viaje, etas) => {
        const viajeEta = etas.find(e => e.viajeId === viaje.viajeId);

        if (viajeEta == null)
            return viaje.eta

        return viajeEta.eta;
    }

    const intervalEtas = (viajes) => {
        clearInterval(intervalEtaId)
        intervalEtaId = window.setInterval(async function () {
            await obtenerEtas(viajes)
        }, 150000);
    }

    const intervalIndicadores = (viajeIds) => {
        clearInterval(intervalIndicadorId)
        intervalIndicadorId = window.setInterval(async function () {
            await obtenerIndicadoresViajes(viajeIds)
        }, 10000);
    }


    const intervalPosicion = (unidades) => {
        clearInterval(intervalPosicionId)
        intervalPosicionId = window.setInterval(async function () {
            await actualizarPosicion(unidades)
        }, 2000);
    }

    const actualizarPosicion = useCallback(async (unidades, centrarMapa) => {
        const undadesEnSeguimiento = viajesEnSeguimiento.flatMap(e => getUnidades(e))

        const posiciones = await api.getPosiciones({unidadIds: [...unidades, ...undadesEnSeguimiento]})

        setPosiciones(posiciones.filter(e => e.lat !== 0 && e.lng !== 0))

        if (centrarMapa)
            setCentrarMapaCount(centrarMapaCount + 1)

        setViajes(v => getPosicionesViajes(posiciones, v))
    }, [viajes, viajesEnSeguimiento])

    const getPosicionesViajes = useCallback((posiciones, viajes) => {

        for (let posicion of posiciones.filter(e => !e.esCaja)) {
            const unidadViajes = viajes.filter(e => e.unidadId === posicion.unidadId)
            for (let viaje of unidadViajes) {
                viaje.velocidad = posicion.velocidad;
                viaje.ubicacion = posicion.ubicacion;
                viaje.motorEncendido = posicion.motorEncendido;
                viaje.indicadoresAdicional = {...viaje.indicadoresAdicional, ...posicion.indicadoresAdicional};
                viaje.gpsUnidad = {
                    fechaActualizacion: posicion.fechaActualizacionGps,
                    actualizando: posicion.gpsActualizando,
                    tiempoSinActualizar: posicion.tiempoSinActualizar,
                    minutosSinActualizar: posicion.minutosSinActualizar,
                };
            }
        }

        for (let posicion of posiciones.filter(e => e.esCaja)) {
            const unidadViajes = viajes.filter(e => e.cajaId === posicion.unidadId)
            for (let viaje of unidadViajes) {
                viaje.gpsCaja = {
                    fechaActualizacion: posicion.fechaActualizacionGps,
                    actualizando: posicion.gpsActualizando,
                    tiempoSinActualizar: posicion.tiempoSinActualizar,
                    minutosSinActualizar: posicion.minutosSinActualizar,
                };
            }
        }

        return viajes.filter(e=>e!=null).map(e => {
            return actualizarColorViaje({...e})
        });
    }, [viajes])

    const cargaInicial = async () => {
        const estaciones = await apiSource.obtener('estaciones', {soloDelUsuario: true});
        const estacionIds = estaciones.map(e => e.id);
        const filtroInicial = {texto: '', estacionIds, estatusId:constantes.estatusViajes.enRuta};
        filtro.borrarFiltro(filtroInicial);
        setUsuarioEstaciones(estacionIds)
        await aplicarFiltro(filtroInicial);
    }

    useEffect(() => {
        cargaInicial().then()
        return () => {
            clearInterval(intervalPosicionId)
            clearInterval(intervalEtaId)
            clearInterval(intervalIndicadorId)
        }
    }, []);

    useEffect(() => {
        actualizarPosicion(unidades, true)
        intervalPosicion(unidades)
    }, [unidades, viajesEnSeguimiento]);

    useEffect(() => {
        obtenerEtas(viajeIds)
        intervalEtas(viajeIds);

        refreshIndicadores().then()
    }, [viajeIds]);


    const refreshIndicadores =  async ()=>{
        intervalIndicadores(viajes);
        await obtenerIndicadoresViajes(viajes)
    }

    const centrarViaje = (viaje) => {
        let posicion = posiciones.find(e => e.unidadId === viaje.unidadId);

        if (posicion == null) {
            posicion = posiciones.find(e => e.unidadId === viaje.cajaId);
        }

        localizar(posicion)
    }

    const agregarViaje = (viaje) => {
        if (viajesEnSeguimiento.findIndex(e => e.viajeId === viaje.id) >= 0) {
            alerts.aviso('El viaje ya se encuentra en seguimiento')
            return;
        }

        setViajesEnSeguimiento([...viajesEnSeguimiento, viaje])
    }

    const suspenderSeguimiento = (viaje) => {
        setViajesEnSeguimiento(viajesEnSeguimiento.filter(e => e.viajeId !== viaje.viajeId))
    }

    const getUnidadesVisibles = () => {
        return unidades.filter(e => !unidadesOcultas.has(e))
    }

    const mostrarRuta = async (unidadId, viajeId, rangoExtendido, fechaHasta) => {
        const ruta = await api.getRuta(unidadId,{fechaHasta})
        setRuta({...ruta, unidadId, viajeId, rangoExtendido:rangoExtendido??false})
    }

    const ocultarRuta = () => {
        setRuta({unidadId: null, viajeId: null, puntos: [], marcadores: [], stops: []})
    }

    const resetearFiltros = () => {
        const filtroInicial = {texto: '', estacionIds: usuarioEstaciones, estatusId: constantes.estatusViajes.enRuta};
        filtro.borrarFiltro(filtroInicial);
        aplicarFiltro(filtroInicial);
    }

    const seleccionarViaje = (viaje) => {
        ocultarVentanas()
        setViajeSeleccionado(viaje)
        setNotificacionSeleccionada(null)
    }

    const ocultarViaje = () => {
        setViajeSeleccionado(null)
    }

    const mostrarFiltro = () => {
        ocultarVentanas()
        filtro.mostrarFiltro();
    }

    const onMostrarGeocercas = (event) => {
        if (event.target.className === 'p-checkbox-box' || event.target.className === 'p-checkbox-icon p-c pi pi-check') {
            return
        }
        ocultarVentanas()
        mostrarSeleccionGeocerca()
    }

    const ocultarVentanas = () => {
        ocultarViaje()
        filtro.ocultarFiltro()
        ocultarSeleccionGeocerca()
        cerrarRuteo()
    }

    const mostrarRuteo = () => {
        ocultarVentanas()
        setVisibleRuteo(true)
    }

    const copiarViajesSeleccionados = async () => {
        let texto = '';

        const viajes = viajesFiltrados.filter(e => viajeVisible(e.viajeId));
        const viajeIds = viajes.map(viaje => viaje.viajeId);
        const rutas = await api.getNombreRutas({viajeIds});

        for (let i=0; i<viajes.length; i++ ) {
            const viaje = viajes[i];
            const ruta = rutas[i];
            const indicador = (viaje.indicadores??{})[tipoIndicador.lastPosition] ?? {};
            const eta = `${utcToString(indicador.eta, 'DD-MMM-YYYY hh:mm A')}`

            texto += `*Truck:* ${viaje.unidad} *Trailer:* ${viaje.caja} *Driver:* ${viaje.operador} *Route:* ${ruta} *Location:* ${viaje.ubicacion} *ETA* ${eta} *Distancia* ${indicador.distanciaKm} Kms\n\n`;
        }

        writeToClipboard(texto)
    }

    const onSetLocalFilter = (viajesFiltrados) => {
        const nuevasUnidades = [...viajesFiltrados.map(v => v?.unidadId), ...viajesFiltrados.map(v => v?.cajaId)].filter(e => e != null && e !== 0)
        nuevasUnidades.sort((a, b) => a - b);
        let cambio = unidades.length !== nuevasUnidades.length;
        if (!cambio) {
            for (let i = 0; i < unidades.length; i++) {
                if (unidades[i] !== nuevasUnidades[i]) {
                    cambio = true;
                }
            }
        }

        if (cambio) {
            setUnidades(nuevasUnidades);
        }

        const map = [
            ...viajesFiltrados.filter(e=>e?.unidadId != null).map(e=>[e.unidadId, e]),
            ...viajesFiltrados.filter(e=>e?.cajaId != null).map(e=>[e.cajaId, e])
        ];

        setUnidadViajeMap( new Map(map))
        setViajesFiltrados( _.orderBy(viajesFiltrados,['color'],['desc']))

    }

    const centrarUnidad = (unidadId)=>{
        const unidad = posiciones.find(e => e.unidadId === unidadId);
        localizar({lat:unidad.lat, lng:unidad.lng}, 15);
    }

    const onChangeMostrar = (valor)=>{

        aplicarFiltro(null, valor.api)
        setMostrar(valor)
    }

    const actualizarViaje = async (viaje)=>{
        setViajes(v => v.map(v=>{
            return viaje.viajeId === v.viajeId ? viaje:v
        }))
    }

    return {
        viajes,
        actualizarViaje,
        unidades,
        posiciones,
        actualizarPosicion,
        mostrarOcultar,
        mostrarViajeEnMapa,
        viajesOcultos,
        viajeVisible,
        todosVisibles,
        cambiarTodosVisibles,
        unidadesOcultas,
        centrarViaje,
        mapa,
        viajesEnSeguimiento,
        agregarViaje,
        suspenderSeguimiento,
        filtro,
        aplicarFiltro,
        resetearFiltros,
        getUnidadesVisibles,
        ruta,
        mostrarRuta,
        mostrarRuteo,
        ocultarRuta,
        viajeSeleccionado,
        seleccionarViaje,
        ocultarViaje,
        centrarMapaCount,
        mostrarFiltro,
        onMostrarGeocercas,
        viajesFiltrados,
        setViajesFiltrados,
        onSetLocalFilter,
        copiarViajesSeleccionados,
        centrarUnidad,
        mostrar,
        onChangeMostrar,
        contadorUnidades,
        unidadViajeMap,
        refreshIndicadores
    }
}

export const useSeguimientoMonitoreo = () => {
    return React.useContext(seguimientoMonitoreoContext)
}

const SeguimientoMonitoreoProvider = ({children}) => {
    const auth = useSeguimientoMonitoreoProvider()
    return (
        <seguimientoMonitoreoContext.Provider value={auth}>
            {children}
        </seguimientoMonitoreoContext.Provider>
    )
}

export default SeguimientoMonitoreoProvider

