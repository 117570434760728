/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { updateArrayAtIndex } from "../../shared/arrayUtility";

const useUnidades = () => {
    const [filtro, setFiltro] = useState(new Map());
    const [unidades, setUnidades] = useState([]);
    const [otrasUnidades, setOtrasUnidades] = useState([]);
    const [unidadesSeleccionadas, setUnidadesSeleccionadas] = useState([]);
    const [otrasUnidadesSeleccionadas, setotrasUnidadesSeleccionadas] = useState([]);
    const [countUnidades, setCountUnidades] = useState(0);

    useEffect(() => {
        actualizarUnidadesSeleccionadas(unidades.map(e=>e.id));
        actualizarOtrasUnidadesSeleccionadas(otrasUnidades.map(e=>e.id));
    }, [unidades, otrasUnidades]);


    const cambiarFiltro = (dia, campo, valor) => {
        let nuevoFiltro;
        if ( filtro.fecha === dia.fecha && filtro[campo] === valor ) {
            nuevoFiltro = {}
        } else {
            nuevoFiltro = {fecha: dia.fecha, [campo]:valor}
        }

        let unidadIds = [];
        for (let u of dia.unidades.values()) {
            if (
                (nuevoFiltro.motivoId == null || u.motivo?.id === nuevoFiltro.motivoId) &&
                (nuevoFiltro.estatusId == null || u.estatusId === nuevoFiltro.estatusId) &&
                (nuevoFiltro.sinOperador == null || u.sinOperador) &&
                (nuevoFiltro.postura == null || u.postura)
            ){
                unidadIds.push(u.unidadId)
            }
        }



        actualizarUnidadesSeleccionadas(unidadIds);

        actualizarOtrasUnidadesSeleccionadas(unidadIds);

        setFiltro(nuevoFiltro);
    };

    const actualizarUnidadesSeleccionadas = (unidadIds) => {
        const filtradas = unidades.filter(e=>unidadIds.indexOf(e.id)>=0);
        setUnidadesSeleccionadas(filtradas);
        setCountUnidades(filtradas.filter((e) => !e.esOtro).length);
    };

    const actualizarOtrasUnidadesSeleccionadas = (unidadIds) => {
        setotrasUnidadesSeleccionadas(otrasUnidades.filter(e=>unidadIds.indexOf(e.id)>=0));
    };

    const setOtraEmpresa = (unidadId, otraEmpresa) => {
        const index = unidades.findIndex((e) => e.id === unidadId);
        setUnidades(updateArrayAtIndex(unidades, index, { ...unidades[index], otraEmpresa }));
    };

    return {
        cambiarFiltro,
        unidadesSeleccionadas,
        setUnidades,
        filtroUnidades: filtro,
        countUnidades,
        setOtraEmpresa,
        otrasUnidades,
        setOtrasUnidades,
        otrasUnidadesSeleccionadas,
    };
};

export default useUnidades;
